import {
  BlackBagTypeEnum,
  ConsentType,
  CoverageBasis,
  DefenseDeductibleDesired,
  LimitsEnum,
  PlSubTypeEnum,
  PolicyDetails,
  ProfessionalLiabilityCoverageSection,
  ProfessionalLiabilityCoverageSectionRequestSchema,
  ProfessionalLiabilityCoverageSectionSchema,
  QuoteTypeEnum,
  UsStateEnum,
  YesNoEnum,
  PolicyTypeEnum,
} from '@/lib/heyapi/types.gen';
import { Selectable } from '../components/Select';
import { WritingCompanyType, ASIC } from '@/lib/utils/consts';

// Defining my own type to control the form the way the UI requires
// Must map from the Backend Schema
export type PLCoverageSectionForm = ProfessionalLiabilityCoverageSection;

export const DefaultValues: Required<PLCoverageSectionForm> = {
  coverageBasis: null,
  consentType: null,
  limitsDesired: null,
  defenseDeductibleDesired: DefenseDeductibleDesired.NONE,
  optinIncreaseLimitsMatchCap: null,
  civilRegulatoryProceed: null,
  largeGroupProcedureMix: null,
  largeGroupProcedureMixExplanation: '',
  largeGroupUnusualRisk: null,
  largeGroupUnusualRiskExplanation: '',
  entityType: null,
  entityName: '',
  entityPriorActs: null,
  numConventions: null,
  componentSocietiesNeedScheduling: null,
  componentSocieties: [],
  missionOfMercyApplies: null,
  missionOfMercyEvents: [],
  numTestingBoardExaminers: null,
  numMissionMercyHygienists: null,
  largeGroupExposureModification: null,
  largeGroupExposureModificationExplanation: null,
  numMissionMercyAll: null,
  policyFlatAdjustment: null,
  additionalInsured: [],
  blackBagCoverage: [],
};

export function getDefaults(
  details: PolicyDetails | undefined
): PLCoverageSectionForm {
  const defaults = { ...DefaultValues };

  // RULE: For non Large Groups, default to Insured Dentist
  // Make Readonly in UI
  // Exclude if state of MD
  if (details?.plSubType != PlSubTypeEnum.LARGE_GROUP) {
    defaults.consentType = ConsentType.INSURED_DENTIST;
  }

  // RULE: If Contract State is MD, default to YES
  // Hide if not MD
  if (details?.policyContractState == UsStateEnum.MD) {
    defaults.civilRegulatoryProceed = YesNoEnum.YES;
  }

  return defaults;
}

export const PLLimitsLabel: Record<LimitsEnum, string> = {
  limit_500k_1m: '$500,000 / $1,000,000',
  limit_1m_1m: '$1,000,000 / $1,000,000',
  limit_1m_2m: '$1,000,000 / $2,000,000',
  limit_1m_3m: '$1,000,000 / $3,000,000',
  limit_2m_3m: '$2,000,000 / $3,000,000',
  limit_2m_4m: '$2,000,000 / $4,000,000',
  limit_2m_6m: '$2,000,000 / $6,000,000',
  limit_3m_3m: '$3,000,000 / $3,000,000',
  limit_3m_6m: '$3,000,000 / $6,000,000',
  limit_4m_4m: '$4,000,000 / $4,000,000',
  limit_5m_5m: '$5,000,000 / $5,000,000',
  limit_5m_6m: '$5,000,000 / $6,000,000',
  limit_5m_8m: '$5,000,000 / $8,000,000',
  limit_2m_2m: '$2,000,000 / $2,000,000',
  limit_3m_9m: '$3,000,000 / $9,000,000',
  limit_500k_1500k: '$500,000 / $1,500,000',
  limit_100k_300k: '$100,000 / $300,000',
  limit_1300k_3900k: '$1,300,000 / $3,900,000',
  limit_1925k_5775k: '$1,925,000 / $5,775,000',
  limit_2050k_6150k: '$2,050,000 / $6,150,000',
  limit_2100k_6300k: '$2,100,000 / $6,300,000',
  limit_2150k_6450k: '$2,150,000 / $6,450,000',
  limit_2200k_6600k: '$2,200,000 / $6,600,000',
  limit_2250k_6750k: '$2,250,000 / $6,750,000',
  limit_2300k_6900k: '$2,300,000 / $6,900,000',
  limit_2350k_7050k: '$2,350,000 / $7,050,000',
  limit_2400k_7200k: '$2,400,000 / $7,200,000',
  limit_2450k_7350k: '$2,450,000 / $7,350,000',
  limit_2500k_7500k: '$2,500,000 / $7,500,000',
  limit_2550k_7650k: '$2,550,000 / $7,650,000',
  limit_2600k_7800k: '$2,600,000 / $7,800,000',
  limit_2650k_7950k: '$2,650,000 / $7,950,000',
  limit_2700k_8100k: '$2,700,000 / $8,100,000',
  limit_2750k_8250k: '$2,750,000 / $8,250,000',
  limit_2800k_8400k: '$2,800,000 / $8,400,000',
  limit_2850k_8550k: '$2,850,000 / $8,550,000',
  limit_2900k_8700k: '$2,900,000 / $8,700,000',
  limit_2950k_8850k: '$2,950,000 / $8,850,000',
};

export const SelectablePL: Array<Selectable<LimitsEnum>> = Object.values(
  LimitsEnum
).map((v) => {
  return {
    value: v,
    label: PLLimitsLabel[v],
  };
});

const DEFAULT_CLAIMS_MADE: Array<LimitsEnum> = [
  LimitsEnum.LIMIT_1M_1M,
  LimitsEnum.LIMIT_1M_3M,
  LimitsEnum.LIMIT_2M_3M,
  LimitsEnum.LIMIT_2M_4M,
  LimitsEnum.LIMIT_2M_6M,
  LimitsEnum.LIMIT_3M_3M,
  LimitsEnum.LIMIT_3M_6M,
  LimitsEnum.LIMIT_3M_9M,
  LimitsEnum.LIMIT_4M_4M,
  LimitsEnum.LIMIT_5M_5M,
  LimitsEnum.LIMIT_5M_6M,
  LimitsEnum.LIMIT_5M_8M,
];

const DEFAULT_OCCURRENCE = [
  LimitsEnum.LIMIT_1M_1M,
  LimitsEnum.LIMIT_1M_3M,
  LimitsEnum.LIMIT_2M_2M,
  LimitsEnum.LIMIT_2M_6M,
];

const AR_OCCURRENCE = [
  LimitsEnum.LIMIT_1M_1M,
  LimitsEnum.LIMIT_1M_3M,
  LimitsEnum.LIMIT_2M_2M,
  LimitsEnum.LIMIT_2M_6M,
  LimitsEnum.LIMIT_2M_3M,
  LimitsEnum.LIMIT_2M_4M,
  LimitsEnum.LIMIT_3M_3M,
  LimitsEnum.LIMIT_4M_4M,
  LimitsEnum.LIMIT_5M_5M,
  LimitsEnum.LIMIT_5M_6M,
  LimitsEnum.LIMIT_5M_8M,
];

const IN_CLAIMS_MADE = [LimitsEnum.LIMIT_500K_1500K, ...DEFAULT_CLAIMS_MADE];

const IN_OCCURRENCE = [LimitsEnum.LIMIT_500K_1500K, ...DEFAULT_OCCURRENCE];

const LA_CLAIMS_MADE = [LimitsEnum.LIMIT_100K_300K, ...DEFAULT_CLAIMS_MADE];
const LA_OCCURRENCE = [LimitsEnum.LIMIT_100K_300K, ...DEFAULT_OCCURRENCE];

const NY_CLAIMS_MADE = [LimitsEnum.LIMIT_1300K_3900K, ...DEFAULT_CLAIMS_MADE];
const NY_OCCURRENCE = [LimitsEnum.LIMIT_1300K_3900K, ...DEFAULT_OCCURRENCE];

const PR_CLAIMS_MADE = [LimitsEnum.LIMIT_500K_1M, ...DEFAULT_CLAIMS_MADE];
const PR_OCCURRENCE = [LimitsEnum.LIMIT_500K_1M, ...DEFAULT_OCCURRENCE];

const VA_OCCURRENCE = [
  LimitsEnum.LIMIT_1M_1M,
  LimitsEnum.LIMIT_1M_3M,
  LimitsEnum.LIMIT_1925K_5775K,
  LimitsEnum.LIMIT_2M_2M,
  LimitsEnum.LIMIT_2M_6M,
  LimitsEnum.LIMIT_2050K_6150K,
  LimitsEnum.LIMIT_2100K_6300K,
  LimitsEnum.LIMIT_2150K_6450K,
  LimitsEnum.LIMIT_2200K_6600K,
  LimitsEnum.LIMIT_2250K_6750K,
  LimitsEnum.LIMIT_2300K_6900K,
  LimitsEnum.LIMIT_2350K_7050K,
  LimitsEnum.LIMIT_2400K_7200K,
  LimitsEnum.LIMIT_2450K_7350K,
  LimitsEnum.LIMIT_2500K_7500K,
  LimitsEnum.LIMIT_2550K_7650K,
  LimitsEnum.LIMIT_2600K_7800K,
  LimitsEnum.LIMIT_2650K_7950K,
  LimitsEnum.LIMIT_2700K_8100K,
  LimitsEnum.LIMIT_2750K_8250K,
  LimitsEnum.LIMIT_2800K_8400K,
  LimitsEnum.LIMIT_2850K_8550K,
  LimitsEnum.LIMIT_2900K_8700K,
  LimitsEnum.LIMIT_2950K_8850K,
  LimitsEnum.LIMIT_3M_9M,
];

const VA_CLAIMS = [
  LimitsEnum.LIMIT_1M_1M,
  LimitsEnum.LIMIT_1M_3M,
  LimitsEnum.LIMIT_1925K_5775K,
  LimitsEnum.LIMIT_2M_3M,
  LimitsEnum.LIMIT_2M_4M,
  LimitsEnum.LIMIT_2M_6M,
  LimitsEnum.LIMIT_2050K_6150K,
  LimitsEnum.LIMIT_2100K_6300K,
  LimitsEnum.LIMIT_2150K_6450K,
  LimitsEnum.LIMIT_2200K_6600K,
  LimitsEnum.LIMIT_2250K_6750K,
  LimitsEnum.LIMIT_2300K_6900K,
  LimitsEnum.LIMIT_2350K_7050K,
  LimitsEnum.LIMIT_2400K_7200K,
  LimitsEnum.LIMIT_2450K_7350K,
  LimitsEnum.LIMIT_2500K_7500K,
  LimitsEnum.LIMIT_2550K_7650K,
  LimitsEnum.LIMIT_2600K_7800K,
  LimitsEnum.LIMIT_2650K_7950K,
  LimitsEnum.LIMIT_2700K_8100K,
  LimitsEnum.LIMIT_2750K_8250K,
  LimitsEnum.LIMIT_2800K_8400K,
  LimitsEnum.LIMIT_2850K_8550K,
  LimitsEnum.LIMIT_2900K_8700K,
  LimitsEnum.LIMIT_2950K_8850K,
  LimitsEnum.LIMIT_3M_3M,
  LimitsEnum.LIMIT_3M_6M,
  LimitsEnum.LIMIT_3M_9M,
  LimitsEnum.LIMIT_4M_4M,
  LimitsEnum.LIMIT_5M_5M,
  LimitsEnum.LIMIT_5M_6M,
  LimitsEnum.LIMIT_5M_8M,
];

export function filteredLimits(
  details: PolicyDetails,
  coverageBasis: CoverageBasis
): Array<LimitsEnum> {
  if (details.policyContractState == UsStateEnum.VA) {
    if (coverageBasis == CoverageBasis.CLAIMS) {
      return VA_CLAIMS;
    }
    return VA_OCCURRENCE;
  }

  if (details.policyContractState == UsStateEnum.PR) {
    if (coverageBasis == CoverageBasis.CLAIMS) {
      return PR_CLAIMS_MADE;
    }
    return PR_OCCURRENCE;
  }

  if (details.policyContractState == UsStateEnum.NY) {
    if (coverageBasis == CoverageBasis.CLAIMS) {
      return NY_CLAIMS_MADE;
    }
    return NY_OCCURRENCE;
  }

  if (details.policyContractState == UsStateEnum.LA) {
    if (coverageBasis == CoverageBasis.CLAIMS) {
      return LA_CLAIMS_MADE;
    }
    return LA_OCCURRENCE;
  }

  if (details.policyContractState == UsStateEnum.IN) {
    if (coverageBasis == CoverageBasis.CLAIMS) {
      return IN_CLAIMS_MADE;
    }
    return IN_OCCURRENCE;
  }

  if (details.policyContractState == UsStateEnum.AR) {
    if (coverageBasis == CoverageBasis.CLAIMS) {
      return DEFAULT_CLAIMS_MADE;
    }
    return AR_OCCURRENCE;
  }

  if (coverageBasis == CoverageBasis.CLAIMS) {
    return DEFAULT_CLAIMS_MADE;
  }

  if (coverageBasis == CoverageBasis.OCCURRENCE) {
    return DEFAULT_OCCURRENCE;
  }

  return [];
}

export function selectableLimits(
  details: PolicyDetails | undefined,
  coverageBasis: CoverageBasis | undefined | null
): Array<Selectable<LimitsEnum>> {
  if (
    details == undefined ||
    coverageBasis == undefined ||
    coverageBasis == null
  ) {
    return [];
  }

  return limitsToSelectable(filteredLimits(details, coverageBasis));
}

export function limitsToSelectable(
  limits: Array<LimitsEnum>
): Array<Selectable<LimitsEnum>> {
  return limits.map((l) => ({ label: PLLimitsLabel[l], value: l }));
}

export const DefenseDeductibleDesiredLabels: Record<
  DefenseDeductibleDesired,
  string
> = {
  none: 'None',
  limit_2_5k: '$2,500',
  limit_5k: '$5,000',
  limit_10k: '$10,000',
  limit_15k: '$15,000',
  limit_25k: '$25,000',
  limit_50k: '$50,000',
  limit_75k: '$75,000',
  limit_100k: '$100,000',
  limit_250k: '$250,000',
};
export const SelectableDefenseDeductible: Array<
  Selectable<DefenseDeductibleDesired>
> = Object.values(DefenseDeductibleDesired).map((v) => {
  return {
    value: v,
    label: DefenseDeductibleDesiredLabels[v],
  };
});
export const BlackBagCoverageLabels: Record<BlackBagTypeEnum, string> = {
  standard: 'Standard',
  higher: 'Higher Limit',
};
export const SelectableBlackBagCoverage: Array<Selectable<BlackBagTypeEnum>> =
  Object.values(BlackBagTypeEnum).map((v) => {
    return {
      value: v,
      label: BlackBagCoverageLabels[v],
    };
  });

type RequiredFields<K> = {
  [Property in keyof K]: boolean;
};

type FormRequiredFields = RequiredFields<Required<PLCoverageSectionForm>>;

const PreliminaryIndicationRequiredFields: FormRequiredFields = {
  consentType: true,
  coverageBasis: true,
  entityName: true,
  entityType: true,
  limitsDesired: true,
  missionOfMercyApplies: true,
  civilRegulatoryProceed: true,
  componentSocieties: false,
  componentSocietiesNeedScheduling: true,
  defenseDeductibleDesired: true,
  entityPriorActs: true,
  largeGroupExposureModification: false,
  largeGroupExposureModificationExplanation: false,
  largeGroupProcedureMix: false,
  largeGroupProcedureMixExplanation: false,
  largeGroupUnusualRisk: false,
  largeGroupUnusualRiskExplanation: false,
  numMissionMercyAll: true,
  numMissionMercyHygienists: true,
  missionOfMercyEvents: false,
  numConventions: true,
  numTestingBoardExaminers: true,
  optinIncreaseLimitsMatchCap: true,
  policyFlatAdjustment: false,
  additionalInsured: false,
};

const BindableQuoteRequiredFields: FormRequiredFields = {
  consentType: true,
  coverageBasis: true,
  entityName: true,
  entityType: true,
  limitsDesired: true,
  missionOfMercyApplies: true,
  civilRegulatoryProceed: true,
  componentSocieties: false,
  componentSocietiesNeedScheduling: true,
  defenseDeductibleDesired: true,
  entityPriorActs: true,
  largeGroupExposureModification: false,
  largeGroupExposureModificationExplanation: false,
  largeGroupProcedureMix: false,
  largeGroupProcedureMixExplanation: false,
  largeGroupUnusualRisk: false,
  largeGroupUnusualRiskExplanation: false,
  numMissionMercyAll: true,
  numMissionMercyHygienists: true,
  missionOfMercyEvents: true, // modified
  numConventions: true,
  numTestingBoardExaminers: true,
  optinIncreaseLimitsMatchCap: true,
  policyFlatAdjustment: false,
  additionalInsured: false,
};

const RollOverRequiredFields: FormRequiredFields = {
  consentType: true,
  coverageBasis: true,
  entityName: true,
  entityType: true,
  limitsDesired: true,
  missionOfMercyApplies: true,
  civilRegulatoryProceed: true,
  componentSocieties: false,
  componentSocietiesNeedScheduling: true,
  defenseDeductibleDesired: true,
  entityPriorActs: true,
  largeGroupExposureModification: false,
  largeGroupExposureModificationExplanation: false,
  largeGroupProcedureMix: false,
  largeGroupProcedureMixExplanation: false,
  largeGroupUnusualRisk: false,
  largeGroupUnusualRiskExplanation: false,
  numMissionMercyAll: true,
  numMissionMercyHygienists: true,
  missionOfMercyEvents: true, // modified
  numConventions: true,
  numTestingBoardExaminers: true,
  optinIncreaseLimitsMatchCap: true,
  policyFlatAdjustment: false,
  additionalInsured: false,
};

const ESRequiredFields: FormRequiredFields = {
  consentType: true,
  coverageBasis: true,
  entityName: true,
  entityType: true,
  limitsDesired: true,
  missionOfMercyApplies: true,
  civilRegulatoryProceed: true,
  componentSocieties: false,
  componentSocietiesNeedScheduling: true,
  defenseDeductibleDesired: true,
  entityPriorActs: true,
  largeGroupExposureModification: false,
  largeGroupExposureModificationExplanation: false,
  largeGroupProcedureMix: false,
  largeGroupProcedureMixExplanation: false,
  largeGroupUnusualRisk: false,
  largeGroupUnusualRiskExplanation: false,
  numMissionMercyAll: true,
  numMissionMercyHygienists: true,
  missionOfMercyEvents: true, // modified
  numConventions: true,
  numTestingBoardExaminers: true,
  optinIncreaseLimitsMatchCap: true,
  policyFlatAdjustment: false,
  additionalInsured: false,
};

export function isRequired(
  field: keyof FormRequiredFields,
  quoteType: QuoteTypeEnum | null | undefined,
  policyType: PolicyTypeEnum | null | undefined,
  writingCompany: WritingCompanyType | undefined
): boolean {
  if (writingCompany === ASIC) {
    return ESRequiredFields[field];
  }

  if (policyType == PolicyTypeEnum.ROLL_OVER) {
    return RollOverRequiredFields[field];
  }

  if (quoteType == QuoteTypeEnum.BINDABLE_QUOTE) {
    return BindableQuoteRequiredFields[field];
  }

  return PreliminaryIndicationRequiredFields[field];
}
/**
 * Function to convert backend schema to one acceptable for the frontend
 * We can use this as a place to handle state differences when setting defaults
 */
export function backToFront(
  backendDetails: ProfessionalLiabilityCoverageSection | undefined
): PLCoverageSectionForm {
  const defaults: PLCoverageSectionForm = {
    ...getDefaults(backendDetails),
  };

  for (const [key, value] of Object.entries(backendDetails || {})) {
    if (value != null || value instanceof String) {
      defaults[key] = value;
    }
  }

  return defaults;
}

export function frontToBack(
  frontend: PLCoverageSectionForm
): ProfessionalLiabilityCoverageSectionRequestSchema {
  /**
   * TODO: Figure out whether to keep if we MUST set form defaults to empty string
   *
  const details: ProfessionalLiabilityCoverageSectionSchema = {
    coverageBasis: frontend.coverageBasis == '' ? null : frontend.coverageBasis,
    consentType: frontend.consentType == '' ? null : frontend.consentType,
    limitsDesired: frontend.limitsDesired == '' ? null : frontend.limitsDesired,
    defenseDeductibleDesired: 'None',
    optinIncreaseLimitsMatchCap:
      frontend.optinIncreaseLimitsMatchCap == ''
        ? null
        : frontend.optinIncreaseLimitsMatchCap,
    civilRegulatoryProceed:
      frontend.civilRegulatoryProceed == ''
        ? null
        : frontend.civilRegulatoryProceed,
    largeGroupProcedureMix:
      frontend.largeGroupProcedureMix == ''
        ? null
        : frontend.largeGroupProcedureMix,
    largeGroupProcedureMixExplanation: '',
    largeGroupUnusualRisk:
      frontend.largeGroupUnusualRisk == ''
        ? null
        : frontend.largeGroupUnusualRisk,
    largeGroupUnusualRiskExplanation: '',
    entityType: frontend.entityType == '' ? null : frontend.entityType,
    entityName: '',
    entityPriorActs:
      frontend.entityPriorActs == '' ? null : frontend.entityPriorActs,
    numConventions:
      frontend.numConventions == '' ? null : frontend.numConventions,
    componentSocietiesNeedScheduling:
      frontend.componentSocietiesNeedScheduling == ''
        ? null
        : frontend.componentSocietiesNeedScheduling,
    componentSocieties: [],
    missionOfMercyApplies:
      frontend.missionOfMercyApplies == ''
        ? null
        : frontend.missionOfMercyApplies,
    missionOfMercyEvents: [],
    numTestingBoardExaminers:
      frontend.numTestingBoardExaminers == ''
        ? null
        : frontend.numTestingBoardExaminers,
  };
  */

  Object.keys(frontend).forEach((field: string) => {
    const typedKey = field as keyof PLCoverageSectionForm;
    if (frontend[typedKey] === '') {
      frontend[typedKey] = null;
    }
  });

  return {
    answers: {
      ...frontend,
    },
  };
}

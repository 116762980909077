import React from 'react';
import type { ComponentType } from 'react';
import {
  createBrowserRouter,
  redirect,
  type PathRouteProps,
  type LoaderFunctionArgs,
} from 'react-router-dom';
import ApplicationLayout from '@/lib/layout';
import DashboardLayout from '@/lib/dashboardLayout';
import type { PolicyWithSummaries, Policy } from '../heyapi';
import { allAuthProvider } from '../auth/auth';
import * as Sentry from '@sentry/react';
import RootErrorBoundary from '../pages/Error';
import { QueryClient } from '@tanstack/react-query';
import { loader as policyLoaderV2 } from './policyLoader';
import { loader as summaryLoader } from './policySummaryLoader';
import { loader as policyWithSummariesLoader } from './policyWithSummariesLoader';
import { defaultHeaders } from '../fetch_utils';
import Loader from '../components/Loader/index';
import ForgotPassword from '../auth/components/ForgotPassword';

function safeLazy<T>(importFn: () => Promise<{ default: ComponentType<T> }>) {
  const storageKey = importFn.toString();
  let retries = 0;
  const maxRetries = 3;
  const baseDelay = 200; // Start with 200ms
  async function tryImport() {
    try {
      return await importFn();
    } catch (error) {
      if (retries < maxRetries) {
        // TODO: Exponential backoff
        retries++;
        const delay = baseDelay * Math.pow(2, retries);
        const jitter = Math.random() * 100;

        await new Promise((resolve) => setTimeout(resolve, delay + jitter));
        retries++;
        return tryImport();
      }
      throw error;
    }
  }

  return React.lazy(async () => {
    try {
      const component = await tryImport();

      sessionStorage.removeItem(storageKey);

      return component;
    } catch (error) {
      if (!sessionStorage.getItem(storageKey)) {
        sessionStorage.setItem(storageKey, 'true');
        window.location.reload();
        return { default: () => <Loader /> };
      }

      throw error;
    }
  });
}
const Home = safeLazy(() => import('@/lib/pages/home'));
const PolicyDetails = safeLazy(() => import('@/lib/pages/forms/policyDetails'));

const PracticeLocations = safeLazy(
  () => import('@/lib/pages/forms/practiceLocations')
);

const ProfLiabCoverage = safeLazy(
  () => import('@/lib/pages/forms/plCoverageSection')
);

const OwnedEntities = safeLazy(() => import('@/lib/pages/forms/ownedEntities'));

const Epli = safeLazy(() => import('@/lib/pages/forms/epli'));

const GeneralLiability = safeLazy(
  () => import('@/lib/pages/forms/generalLiability')
);

const PracticeOwner = safeLazy(
  () => import('@/lib/pages/forms/plPracticeOwners')
);

const ERISAFidAndEmployee = safeLazy(() => import('@/lib/pages/forms/erisa'));

const CommercialPropertyLocations = safeLazy(
  () => import('@/lib/pages/forms/commercialPropertyLocations')
);

const CommercialPropertyCoverages = safeLazy(
  () => import('@/lib/pages/forms/commercialPropertyCoverages')
);

const PolicyDecisions = safeLazy(
  () => import('@/lib/pages/forms/policyDecisions')
);

const DocumentWarehouse = safeLazy(
  () => import('@/lib/pages/forms/documentWarehouse')
);

const ProfLiabDentist = safeLazy(
  () => import('@/lib/pages/forms/profLiabDentist')
);

const Transactions = safeLazy(() => import('@/lib/pages/forms/transactions'));

const Claims = safeLazy(() => import('@/lib/pages/forms/claims'));

const Login = safeLazy(() => import('@/lib/pages/login'));

const ResetPassword = safeLazy(
  () => import('@/lib/auth/components/ResetPassword')
);

const Page404 = safeLazy(() => import('@/lib/pages/404'));

const Profile = safeLazy(() => import('@/lib/pages/profile/index'));

const PrintQueue = safeLazy(() => import('@/lib/pages/printQueue/index'));

const Renewals = safeLazy(() => import('@/lib/pages/renewals/index'));

const Common = safeLazy(() => import('@/lib/pages/forms/commonForms'));

async function protectedLoader({ request }: LoaderFunctionArgs) {
  await allAuthProvider.checkAuth();
  if (!allAuthProvider.isAuthenticated) {
    let params = new URLSearchParams();
    params.set('from', new URL(request.url).pathname);
    return redirect('/auth/login?' + params.toString());
  }

  return allAuthProvider;
}

const BASE_URL = `${import.meta.env.VITE_BACKEND_URL}/api/v1/policy`;

const qc = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 10,
      retry: 3,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

// TODO: Protect everything within policy
// For loader to execute correctly, the elements must be within the page NOT within the layout!
export const router = sentryCreateBrowserRouter([
  {
    path: 'auth/login',
    element: <Login />,
    loader: () => allAuthProvider.checkAuth(),
  },
  {
    path: 'auth/reset-password/:userId/:token',
    element: <ResetPassword />,
  },
  {
    path: 'auth/forgot-password',
    element: <ForgotPassword />,
  },

  {
    path: '*',
    element: <Page404 />,
  },

  {
    id: 'root',
    element: <DashboardLayout />,
    path: '/',
    loader: protectedLoader,
    errorElement: <RootErrorBoundary />,
    children: [
      {
        index: true,
        element: <Home />,
        loader: summaryLoader,
      },
      {
        path: 'load-renewals',
        element: <Renewals />,
      },
      {
        path: 'profile',
        element: <Profile />,
        loader: protectedLoader,
      },
      {
        path: 'print-queue',
        element: <PrintQueue />,
        loader: protectedLoader,
      },
    ],
  },
  {
    id: 'policy-root',
    loader: protectedLoader,
    path: '/policy/:id',
    element: <ApplicationLayout />,
    errorElement: <RootErrorBoundary />,

    children: [
      {
        path: 'policy-details',
        element: <PolicyDetails />,
        loader: policyLoaderV2(qc),
      },
      {
        path: 'practice-locations',
        element: <PracticeLocations />,
        loader: async ({ params, request }: LoaderFunctionArgs) => {
          const { id } = params;

          if (!id) {
            return null;
          }

          const headers = defaultHeaders();
          headers.append('Accept', 'application/json');

          const options: RequestInit = {
            credentials: 'include',
            headers,
            method: 'GET',
          };
          const data = await fetch(`${BASE_URL}/${id}`, options);

          const policy = (await data.json()) as Policy;

          const locations = policy.locations || [];
          const location = locations.at(0);

          if (location) {
            return redirect(`${request.url}/${location.pk}`);
          }

          return { policyId: id };
        },
      },
      {
        path: 'common',
        element: <Common />,
      },

      {
        path: 'practice-locations/:locationId',
        element: <PracticeLocations />,
        loader: policyLoaderV2(qc),
      },
      {
        path: 'prof-liability-coverage',
        element: <ProfLiabCoverage />,
        loader: policyWithSummariesLoader(qc),
      },
      {
        path: 'prof-liability-dentist',
        element: <ProfLiabDentist />,
        loader: policyWithSummariesLoader(qc),
      },
      {
        path: 'prof-liability-dentist/:dentistId',
        element: <ProfLiabDentist />,
      },

      {
        path: 'prof-liability-practice-owners',
        element: <PracticeOwner />,
        loader: policyLoaderV2(qc),
      },
      {
        path: 'owned-entities',
        element: <OwnedEntities />,
        loader: async ({ params, request }: LoaderFunctionArgs) => {
          // TODO: Change to actual request once API is ready
          const { id } = params;

          if (!id) {
            return null;
          }

          const headers = defaultHeaders();
          headers.append('Accept', 'application/json');

          const options: RequestInit = {
            credentials: 'include',
            headers,
            method: 'GET',
          };
          const data = await fetch(`${BASE_URL}/${id}`, options);

          const policy = (await data.json()) as Policy;

          const entities = policy.ownedEntities || [];
          const entity = entities.at(0);

          if (entity) {
            return redirect(`${request.url}/${entity.pk}`);
          }

          return { policyId: id };
        },
      },
      {
        path: 'owned-entities/:entityId',
        element: <OwnedEntities />,
        loader: policyLoaderV2(qc),
      },

      {
        path: 'epli',
        element: <Epli />,
        loader: policyLoaderV2(qc),
      },
      {
        path: 'general-liability',
        loader: policyLoaderV2(qc),
        element: <GeneralLiability />,
      },
      {
        path: 'erisa-fiduciary-and-employee-benefits',
        element: <ERISAFidAndEmployee />,
        loader: policyLoaderV2(qc),
      },
      // {
      //   path: 'commercial-property-locations',
      //   element: <CommercialPropertyLocations />,
      // },
      {
        path: 'commercial-property-locations',
        element: <CommercialPropertyLocations />,
        loader: async ({ params, request }) => {
          // TODO: Change to actual request once API is ready
          const { id } = params;

          if (!id) {
            return null;
          }

          const headers = defaultHeaders();
          headers.append('Accept', 'application/json');

          const options: RequestInit = {
            credentials: 'include',
            headers,
            method: 'GET',
          };
          const data = await fetch(`${BASE_URL}/${id}`, options);

          const policy = (await data.json()) as Policy;

          const locations = policy.commercialProperty?.locations || [];
          const location = locations.at(0);

          if (location) {
            return redirect(`${request.url}/${location.pk}`);
          }

          return { policyId: id };
        },
      },
      {
        path: 'commercial-property-locations/:locationId',
        element: <CommercialPropertyLocations />,
        loader: policyLoaderV2(qc),
      },
      {
        path: 'commercial-property-coverages',
        element: <CommercialPropertyCoverages />,
        loader: policyLoaderV2(qc),
      },
      {
        path: 'policy-decisions',
        element: <PolicyDecisions />,

        loader: policyLoaderV2(qc),
      },
      {
        path: 'document-warehouse',
        element: <DocumentWarehouse />,
      },
      {
        path: 'claims',
        element: <Claims />,
        loader: policyLoaderV2(qc),
      },
      {
        path: 'transactions',
        element: <Transactions />,
        loader: async ({ params, request }) => {
          // TODO: Change to actual request once API is ready
          const { id } = params;

          if (!id) {
            return null;
          }

          const headers = defaultHeaders();
          headers.append('Accept', 'application/json');

          const options: RequestInit = {
            credentials: 'include',
            headers,
            method: 'GET',
          };
          const data = await fetch(`${BASE_URL}/${id}`, options);

          const policy = (await data.json()) as Policy;

          const transactions = policy.transactions || [];
          const transaction = transactions.at(0);

          if (transaction) {
            return redirect(`${request.url}/${transaction.transactionNumber}`);
          }

          return { policyId: id };
        },
      },
      {
        path: 'transactions/:transactionNumber',
        element: <Transactions />,
        loader: policyLoaderV2(qc),
      },
    ],
  },
]);
export const privateRoutes: Array<PathRouteProps> = [];

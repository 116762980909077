import { useContext, useEffect } from 'react';
import {
  type UseFormRegister,
  type Control,
  useFieldArray,
  useWatch,
  UseFormSetValue,
  FieldErrors,
} from 'react-hook-form';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

import { PracticeLocation } from '@/lib/heyapi';
import { Select } from '@/lib/pages/forms/components/Select';
import { getDisplayAddressFromAddress } from '../../helpers';
import { PermissionsContext } from '@/lib/layout';
import {
  BlackBagCoverage,
  BlackBagCoverageDict,
  PracticeLocationSummary,
} from '@/lib/heyapi/types.gen';
import { v4 as uuidv4 } from 'uuid';
import {
  PLCoverageSectionForm,
  SelectableBlackBagCoverage,
} from '../../plCoverageSection/types';
import { Textarea } from '../Textarea';
import React from 'react';

export const AddAnotherPLBlackBagCoverage = React.forwardRef(
  (
    {
      control,
      setValue,
      errors,
      locationsSummary,
      register,
    }: {
      locationsSummary: Array<PracticeLocationSummary>;
      setValue: UseFormSetValue<PLCoverageSectionForm>;
      control: Control<PLCoverageSectionForm>;
      errors: FieldErrors<PLCoverageSectionForm>;
      register: UseFormRegister<PLCoverageSectionForm>;
    },
    ref: React.ForwardedRef<HTMLDivElement> // Specify the ref type (adjust if needed)
  ) => {
    const { editMode } = useContext(PermissionsContext);
    const { fields, append, remove, replace } = useFieldArray({
      control,
      name: 'blackBagCoverage',
    });

    const blackBagCoverageValues = useWatch({
      control,
      name: 'blackBagCoverage',
      defaultValue: [],
    });

    // Values for the black bag coverage
    // Updates the values when the locationsSummary is changed
    useEffect(() => {
      // When we load the `locationsSummary` or the `locationsSummary` is changed, update the values in the blackBagCoverageValues watch
      const newBlackBagCoverageValues = locationsSummary
        .map((location) => ({
          blackBagCoverage: location.blackBagCoverage,
          practiceLocationPk: location.pk, // Map locationPk from locationsSummary
        }))
        .filter((coverage) => coverage.blackBagCoverage) // Remove any locations that don't have black bag coverage
        .reduce((acc, curr) => {
          if (
            !acc.some((v) => v.practiceLocationPk === curr.practiceLocationPk)
          ) {
            // Only add the value if it doesn't already exist
            const blackBagDict = {
              ...curr.blackBagCoverage,
              practiceLocationPk: curr.practiceLocationPk, // Map locationPk from locationsSummary
            };
            acc.push(blackBagDict as BlackBagCoverageDict);
          }
          return acc;
        }, [] as BlackBagCoverageDict[]);

      // Set the new values
      replace(newBlackBagCoverageValues);
    }, [locationsSummary]);

    useEffect(() => {
      blackBagCoverageValues.forEach((coverage, index) => {
        if (coverage.bagType === 'standard') {
          setValue(`blackBagCoverage.${index}.totalLimit`, 4000);
        }
      });
    }, [blackBagCoverageValues, setValue]);

    const getSelectablePracticeLocations = (
      locations: PracticeLocationSummary[],
      currentIndex: number
    ) => {
      // Get all selected practice location PKs except for the current row
      const selectedLocationPks = (blackBagCoverageValues || [])
        .map((value, idx) =>
          idx !== currentIndex && value?.practiceLocationPk
            ? value.practiceLocationPk
            : null
        )
        .filter(Boolean);

      return locations
        .filter((location) => !selectedLocationPks.includes(location.pk))
        .map((l) => ({
          value: l.pk,
          label: getDisplayAddressFromAddress(l.address),
        }));
    };

    return (
      <div ref={ref}>
        <div className="flex items-start">
          <h2 className="mb-4 w-3/4 text-lg">Black Bag Coverage</h2>

          <div className="flex w-1/4 justify-end">
            <Button
              onClick={() => {
                const insured: BlackBagCoverageDict = {
                  bagType: null,
                  totalLimit: 0.0,
                  practiceLocationPk: '',
                  description: '',
                };
                append(insured);
              }}
              type="button"
              variant="outline"
              className="h-auto px-3 py-1"
              disabled={!editMode}
            >
              + Add Black Bag Coverage
            </Button>
          </div>
        </div>
        <div className="flex flex-col rounded border">
          <div className="grid grid-cols-[minmax(0,1fr),minmax(0,1fr),minmax(0,1fr),minmax(0,1fr)0.3fr] gap-2 border-b bg-brand-100 text-sm text-brand-400">
            <div className="flex flex-col space-y-2 px-2 py-3">
              Black Bag Type
            </div>
            <div className="flex flex-col space-y-2 px-2 py-3">Total Limit</div>
            <div className="flex flex-col space-y-2 px-2 py-3">
              Practice Location
            </div>
            <div className="flex flex-col space-y-2 px-2 py-3">Description</div>
            <div className="flex flex-col space-y-2 px-2 py-3">Actions</div>
          </div>
          {!fields.length ? (
            <div className="flex flex-col space-y-2 px-2 py-3 text-sm">
              Click &quot;Add Black Bag Coverage&quot; to add Black Bag Coverage
            </div>
          ) : null}
          {fields.map((item, index) => {
            return (
              <div
                className="grid w-full grid-cols-[minmax(0,1fr),minmax(0,1fr),minmax(0,1fr),minmax(0,1fr),0.3fr] gap-2"
                key={item.id}
              >
                <div className="flex flex-col space-y-2 px-2 py-4">
                  <Select
                    placeholder="Type here"
                    control={control}
                    options={SelectableBlackBagCoverage}
                    label={''}
                    orientation="vertical"
                    required={true}
                    {...register(`blackBagCoverage.${index}.bagType`)}
                  />
                </div>

                <div className="flex flex-col space-y-2 px-2 py-4">
                  <Input
                    placeholder="Type here"
                    type="number"
                    required={true}
                    {...register(`blackBagCoverage.${index}.totalLimit`, {
                      validate: (value) =>
                        blackBagCoverageValues?.[index]?.bagType === 'higher'
                          ? value > 4000 ||
                            'Total limit must be greater than 4000'
                          : true,
                    })}
                    disabled={
                      blackBagCoverageValues?.[index]?.bagType === 'standard'
                    }
                  />
                  {errors.blackBagCoverage?.[index]?.totalLimit && (
                    <span className="text-red-500">
                      {errors.blackBagCoverage[index].totalLimit.message}
                    </span>
                  )}
                </div>

                <div className="flex w-full flex-col space-y-2 px-2 py-4">
                  <Select
                    options={getSelectablePracticeLocations(
                      locationsSummary,
                      index
                    )}
                    control={control}
                    placeholder="Select"
                    label=""
                    orientation="vertical"
                    required={true}
                    {...register(
                      `blackBagCoverage.${index}.practiceLocationPk`
                    )}
                  />
                </div>
                <div className="flex flex-col space-y-2 px-2 py-2">
                  <Textarea
                    placeholder="Type here"
                    control={control}
                    label=""
                    orientation="vertical"
                    {...register(`blackBagCoverage.${index}.description`, {
                      validate: (value) =>
                        value == null ||
                        value == undefined ||
                        value == '' ||
                        value.replace(/\s/g, '') === ''
                          ? 'Description is required'
                          : true,
                    })}
                  />
                </div>

                <div className="mb-4 flex items-center space-y-2 px-2 py-3">
                  <Button
                    variant="ghost"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      remove(index);
                    }}
                    disabled={!editMode}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.86576 1.10156C5.58961 1.10156 5.36576 1.32542 5.36576 1.60156C5.36576 1.8777 5.58961 2.10156 5.86576 2.10156H10.1324C10.4086 2.10156 10.6324 1.8777 10.6324 1.60156C10.6324 1.32542 10.4086 1.10156 10.1324 1.10156H5.86576ZM5.26576 4.2349H10.7324V12.8682L5.26576 12.8682V4.2349ZM10.7324 3.20156C10.8213 3.20156 10.9074 3.21315 10.9894 3.2349H12.2658C12.5419 3.2349 12.7658 3.45875 12.7658 3.7349C12.7658 4.01104 12.5419 4.2349 12.2658 4.2349H11.7324V12.8682C11.7324 13.4205 11.2847 13.8682 10.7324 13.8682H5.26576C4.71347 13.8682 4.26576 13.4205 4.26576 12.8682V4.2349H3.73242C3.45628 4.2349 3.23242 4.01104 3.23242 3.7349C3.23242 3.45875 3.45628 3.2349 3.73242 3.2349H5.00876C5.09076 3.21315 5.17691 3.20156 5.26576 3.20156H10.7324Z"
                        fill="#09090B"
                      />
                    </svg>
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);

import { useContext } from 'react';
import { formatDate } from '@/lib/utils/dates';

import type { Control, FieldValues, Path } from 'react-hook-form';

import {
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';

import { Input } from '@/components/ui/input';

import { ReadOnlyField } from '@/lib/pages/forms/components';
import { PermissionsContext } from '@/lib/layout';

function DatePicker<T extends FieldValues>({
  control,
  name,
  label,
  orientation = 'horizontal',
  readOnly,
  // placeholder,
  min,
  max,
  required = false,
  disabled,
}: {
  control: Control<T>;
  name: Path<T>;
  label?: string;
  orientation: 'horizontal' | 'vertical';
  min: string | undefined;
  max: string | undefined;
  required: boolean | undefined;
  readOnly?: boolean;
  disabled?: boolean;
}) {
  const { editMode } = useContext(PermissionsContext);

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => {
        if (!editMode) {
          return (
            <ReadOnlyField
              label={label}
              value={formatDate(field.value)}
              orientation={orientation}
            />
          );
        } else {
          return (
            <FormItem
              className={`flex ${orientation === 'horizontal' ? 'items-center gap-4' : 'w-full flex-col'}`}
            >
              {label && (
                <FormLabel
                  className={`${orientation === 'horizontal' ? 'w-1/5' : 'mb-2'} font-semibold leading-[1.5rem]`}
                >
                  {label}
                  {required && <span className="text-red-400">*</span>}
                </FormLabel>
              )}
              <div
                className={`${orientation === 'horizontal' ? 'w-2/5' : ''} !mt-0`}
              >
                <div className="flex w-full items-center">
                  <Input
                    placeholder="Type here"
                    {...field}
                    disabled={readOnly || disabled}
                    required={required}
                    type="date"
                    min={min}
                    max={max}
                  />
                </div>
                <FormMessage />
              </div>
            </FormItem>
          );
        }
      }}
    />
  );
}

export { DatePicker as DateInput };

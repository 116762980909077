import { forgotPassword } from '@/lib/auth/allauth';
import { Link, useParams } from 'react-router-dom';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { useMutation } from '@tanstack/react-query';
import BaseLogin from './BaseLogin';

const ForgotPasswordSchema = z.object({
  email: z.string(),
});

type ForgotPasswordSchemaType = z.infer<typeof ForgotPasswordSchema>;

const buttonStyles = {
  display: 'inline-block',
  padding: '10px 20px',
  backgroundColor: '#007BFF',
  color: 'white',
  textDecoration: 'none',
  borderRadius: '5px',
  fontFamily: 'sans-serif',
  fontSize: '14px',
};

export default function ForgotPassword() {
  const form = useForm<ForgotPasswordSchemaType>({
    resolver: zodResolver(ForgotPasswordSchema),
  });

  const changeMutation = useMutation({
    mutationFn: async (data: ForgotPasswordSchemaType) => {
      return await forgotPassword({
        email: data.email,
      });
    },
  });

  function onSubmit(data: ForgotPasswordSchemaType) {
    changeMutation.mutate(data);
  }

  //   // TODO: Better error handling
  console.log(changeMutation);
  if (changeMutation.data?.response.status === 200) {
    return (
      <BaseLogin
        renderComponent={
          <div>
            <Link to="/" style={buttonStyles}>
              Go back to home page
            </Link>
          </div>
        }
        title={'Email sent'}
        description="Please check your email for the link to reset your password"
      />
    );
  } else if (
    changeMutation.data?.response?.status &&
    changeMutation.data?.response?.status >= 400
  ) {
    return (
      <BaseLogin
        renderComponent={
          <div>
            <Link to="/" style={buttonStyles}>
              Go back to home page
            </Link>
          </div>
        }
        title={'There was an error'}
        description="please try again later or contact your administrator."
      />
    );
  }

  return (
    <BaseLogin
      renderComponent={
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="mx-auto flex max-w-md flex-col gap-4 pt-24"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      autoComplete="Your email address..."
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <Button type="submit" className="bg-blue-600">
              Send Email to reset Password
            </Button>
          </form>
        </Form>
      }
      title="Forgot your password?"
      description="We will send you a url to reset it to your email address"
    />
  );
}

import { useContext } from 'react';
import {
  type UseFormRegister,
  type Control,
  useFieldArray,
} from 'react-hook-form';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';

import {
  SelectableUnitedStates,
  SelectableYesNo,
} from '@/lib/pages/forms/fields';

import type { DentistInformation } from '@/lib/heyapi';

import { PermissionsContext } from '@/lib/layout';

/**
 * Simplifying this compared to above since this is very narrowly defined to one form
 */
export function AddAnotherLicense({
  control,
  register,
  required,
  disabled,
}: {
  control: Control<DentistInformation>;
  register: UseFormRegister<DentistInformation>;
  required?: boolean;
  disabled?: boolean;
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'licenses',
  });

  const { editMode } = useContext(PermissionsContext);

  return (
    <div>
      <div className="flex items-start">
        <p className="mb-4 w-3/4 font-semibold">State Dental Licenses</p>
        <div className="flex w-1/4 justify-end gap-2">
          <Button
            onClick={() => {
              append({
                licenseNumber: '',
                licenseState: '',
                licenseStatus: '',
                priorDisciplinaryAction: null,
              });
            }}
            type="button"
            variant="outline"
            className="h-auto px-3 py-1"
            disabled={!editMode}
          >
            + Add license
          </Button>
          {false && (
            <Button
              disabled
              // disabled={!editMode}
              className="flex h-auto text-nowrap rounded-[8px] bg-brand-medium-green px-3 py-1 text-sm text-[white] transition duration-300 hover:bg-[#16a34abf]"
            >
              Get/Update License Information
            </Button>
          )}
        </div>
      </div>
      <div className="mb-4 flex flex-col rounded border">
        <div className="grid grid-cols-[1fr,1fr,1fr,1fr] gap-4 border-b bg-brand-100 text-sm text-brand-400">
          <div className="flex flex-col space-y-2 px-4 py-3">
            License Number
          </div>
          <div className="flex flex-col space-y-2 px-4 py-3">State</div>
          <div className="flex flex-col space-y-2 px-4 py-3">
            Status of License
          </div>
          <div className="flex flex-col space-y-2 px-4 py-3">
            Prior Disciplinary Action
          </div>
        </div>
        {!fields.length ? (
          <div className="flex flex-col space-y-2 px-4 py-3 text-sm">
            Click &quot;Add license&quot; to add new license
          </div>
        ) : null}
        {fields.map((item, index) => {
          const stateFieldProps = register(`licenses.${index}.licenseState`);
          const disciplinaryActionFieldProps = register(
            `licenses.${index}.priorDisciplinaryAction`
          );
          const selectedStateLabel =
            SelectableUnitedStates.find(
              (option) => option.value === item.licenseState
            )?.label || '';
          const selectedPriorDisciplinaryAction =
            SelectableYesNo.find(
              (option) => option.value === item.priorDisciplinaryAction
            )?.label || '';

          return (
            <div
              className="grid w-full grid-cols-[1fr,1fr,1fr,1fr] gap-4"
              key={item.id}
            >
              <div className="flex flex-col space-y-2 px-4 py-3">
                {!editMode ? (
                  <div className="flex w-full flex-col">
                    <div
                      className={`h-10 rounded border border-[#D4D4D8] bg-brand-50 px-3 py-2`}
                    >
                      {item.licenseNumber || 'N/A'}
                    </div>
                  </div>
                ) : (
                  <Input
                    required={required}
                    disabled={disabled}
                    className="border-none"
                    placeholder="Type here"
                    {...register(`licenses.${index}.licenseNumber`)}
                  />
                )}
              </div>

              <div className="flex flex-col space-y-2 px-4 py-3">
                {!editMode ? (
                  <div className="flex w-full flex-col">
                    <div
                      className={`h-10 rounded border border-[#D4D4D8] bg-brand-50 px-3 py-2`}
                    >
                      {selectedStateLabel || 'N/A'}
                    </div>
                  </div>
                ) : (
                  <Select
                    name={stateFieldProps.name}
                    required={required}
                    disabled={disabled}
                    defaultValue={item.licenseState}
                    onValueChange={(e) =>
                      stateFieldProps.onChange({
                        target: { name: stateFieldProps.name, value: e },
                      })
                    }
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          ref={stateFieldProps.ref}
                          placeholder={'State'}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {SelectableUnitedStates.map(({ value, label }) => (
                        <SelectItem key={value} value={value}>
                          {label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              </div>

              <div className="flex flex-col space-y-2 px-4 py-3">
                {!editMode ? (
                  <div className="flex w-full flex-col">
                    <div
                      className={`h-10 rounded border border-[#D4D4D8] bg-brand-50 px-3 py-2`}
                    >
                      {item.licenseStatus || 'N/A'}
                    </div>
                  </div>
                ) : (
                  <Input
                    className="border-none"
                    placeholder="Type here"
                    required={required}
                    disabled={disabled}
                    {...register(`licenses.${index}.licenseStatus`)}
                  />
                )}
              </div>

              <div className="flex flex-col space-y-2 px-4 py-3">
                {!editMode ? (
                  <div className="flex w-full flex-col">
                    <div
                      className={`h-10 rounded border border-[#D4D4D8] bg-brand-50 px-3 py-2`}
                    >
                      {selectedPriorDisciplinaryAction || 'N/A'}
                    </div>
                  </div>
                ) : (
                  <Select
                    name={disciplinaryActionFieldProps.name}
                    defaultValue={item.priorDisciplinaryAction || ''}
                    required={required}
                    disabled={disabled}
                    onValueChange={(e) =>
                      disciplinaryActionFieldProps.onChange({
                        target: {
                          name: disciplinaryActionFieldProps.name,
                          value: e,
                        },
                      })
                    }
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          ref={disciplinaryActionFieldProps.ref}
                          placeholder={'Select an Option'}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {SelectableYesNo.map(({ value, label }) => (
                        <SelectItem key={value} value={value}>
                          {label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}

                {/* <Input
                  className="border-none"
                  placeholder="Type here"
                  {...register(`licenses.${index}.priorDisciplinaryAction`)}
                /> */}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AddAnotherLicense;

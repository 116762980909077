import { Link, useLocation, useParams } from 'react-router-dom';
import { ChevronLeftIcon } from '@radix-ui/react-icons';
import { Profile } from '../components/auth/LoggedInUser';
import { InsuranceLinesEnum, PlSubTypeEnum } from '../heyapi';
import { useQuery } from '@tanstack/react-query';
import { glacierPolicyApiGetPolicyOptions } from '../heyapi/@tanstack/react-query.gen';

type NavRoute = {
  to: string;
  description: string;
  icon?: JSX.Element | string;
  hideIf: (
    selectedLines: Array<InsuranceLinesEnum>,
    subtype: PlSubTypeEnum | null | undefined
  ) => boolean;
};

// FEATURE FLAG
const FeatureFlag_EnableLossRuns =
  import.meta.env.VITE_ENABLE_LOSS_RUNS === 'true';

/**
 * Use to define the links for navigation in the app
 * https://reactrouter.com/en/6.24.1/components/link
 */
const ROUTES: Array<NavRoute> = [
  {
    to: 'policy-details',
    description: 'Policy Details',
    icon: '',
    hideIf(_lines, _subtype) {
      return false;
    },
  },
  {
    to: 'practice-locations',
    description: 'Practice Locations',
    icon: '',
    hideIf(_lines, _subtype) {
      return false;
    },
  },
  {
    to: 'common',
    description: 'Policy Common Forms',
    icon: '',
    hideIf(_lines, _subtype) {
      return false;
    },
  },

  {
    to: 'prof-liability-coverage',
    description: 'Professional Liability Coverage',
    icon: '',
    hideIf(lines, _subtype) {
      return !lines.includes(InsuranceLinesEnum.PL);
    },
  },
  {
    to: 'prof-liability-dentist',
    description: 'Professional Liability Dentists',
    icon: '',
    hideIf(lines, subtype) {
      return (
        !lines.includes(InsuranceLinesEnum.PL) ||
        subtype == PlSubTypeEnum.DENTAL_ASSOCIATION ||
        subtype == PlSubTypeEnum.TESTING_BOARD
      );
    },
  },
  {
    to: 'prof-liability-practice-owners',
    description: 'Professional Liability Practice Owners',
    icon: '',
    hideIf(lines, subtype) {
      return (
        !lines.includes(InsuranceLinesEnum.PL) ||
        subtype == PlSubTypeEnum.VOLUNTEER_ONLY ||
        subtype == PlSubTypeEnum.FACULTY_ONLY ||
        subtype == PlSubTypeEnum.TESTING_BOARD ||
        subtype == PlSubTypeEnum.DENTAL_ASSOCIATION
      );
    },
  },
  {
    to: 'owned-entities',
    description: 'Owned Entities',
    icon: '',
    hideIf(_lines, _subtype) {
      return false;
    },
  },
  {
    to: 'epli',
    description: 'Employment Practices Liability Coverage',
    icon: '',

    hideIf(lines, subtype) {
      return (
        !lines.includes(InsuranceLinesEnum.EPLI) ||
        subtype == PlSubTypeEnum.VOLUNTEER_ONLY
      );
    },
  },
  {
    to: 'general-liability',
    description: 'General Liability Coverage',
    icon: '',

    hideIf(lines, subtype) {
      return (
        !lines.includes(InsuranceLinesEnum.GL) ||
        subtype == PlSubTypeEnum.VOLUNTEER_ONLY ||
        subtype == PlSubTypeEnum.FACULTY_ONLY ||
        subtype == PlSubTypeEnum.NEW_GRAD
      );
    },
  },
  {
    to: 'erisa-fiduciary-and-employee-benefits',
    description: 'ERISA Fiduciary & Employee Benefits Liability Coverage',
    icon: '',

    hideIf(lines, subtype) {
      return (
        !lines.includes(InsuranceLinesEnum.ERISA) ||
        subtype == PlSubTypeEnum.VOLUNTEER_ONLY ||
        subtype == PlSubTypeEnum.FACULTY_ONLY ||
        subtype == PlSubTypeEnum.NEW_GRAD
      );
    },
  },

  {
    to: 'commercial-property-locations',
    description: 'Commercial Property Location Coverages',
    icon: '',

    hideIf(lines, subtype) {
      return (
        !lines.includes(InsuranceLinesEnum.CP) ||
        subtype == PlSubTypeEnum.VOLUNTEER_ONLY ||
        subtype == PlSubTypeEnum.FACULTY_ONLY ||
        subtype == PlSubTypeEnum.NEW_GRAD
      );
    },
  },
  {
    to: 'commercial-property-coverages',
    description: 'Commercial Property Policy Coverages',
    icon: '',
    hideIf(lines, subtype) {
      return (
        !lines.includes(InsuranceLinesEnum.CP) ||
        subtype == PlSubTypeEnum.VOLUNTEER_ONLY ||
        subtype == PlSubTypeEnum.FACULTY_ONLY ||
        subtype == PlSubTypeEnum.NEW_GRAD
      );
    },
  },

  {
    to: 'policy-decisions',
    description: 'Policy Decisions',
    icon: '',
    hideIf(_subtype) {
      return false;
    },
  },
  {
    to: 'document-warehouse',
    description: 'Document Warehouse',
    icon: '',
    hideIf(_subtype) {
      return false;
    },
  },
  {
    to: 'claims',
    description: 'Claims',
    icon: '',

    hideIf(_subtype) {
      return !FeatureFlag_EnableLossRuns;
    },
  },
  {
    to: 'transactions',
    description: 'Transactions',
    icon: '',

    hideIf(_subtype) {
      return true;
    },
  },
];

const Navigation = () => {
  const { id } = useParams();

  const { data } = useQuery({
    ...glacierPolicyApiGetPolicyOptions({
      path: {
        account_id: id!,
      },
    }),
  });

  return (
    <NavigationView
      selectedLines={data?.details?.insuranceLinesDesired || []}
      subtype={data?.details?.plSubType}
    />
  );
};

const NavigationView = ({
  selectedLines,
  subtype,
}: {
  selectedLines: Array<InsuranceLinesEnum>;
  subtype: PlSubTypeEnum | undefined | null;
}) => {
  const location = useLocation();

  const env = import.meta.env.VITE_SENTRY_ENV;

  const showBadge = !env.startsWith('prod');

  const getBadgeClass = () => {
    if (env == 'staging') {
      return 'bg-yellow-700';
    }

    return 'bg-green-700';
  };

  return (
    <aside className="text-0 fixed inset-y-0 left-0 z-10 hidden w-[20vw] flex-col border-r bg-brand-dark-green sm:flex">
      <div className="flex min-h-[52px] items-center border-0 border-b border-b-[#ffffff19]">
        <Link to="/" key="Home" className="my-4 ml-[16px] flex">
          <img src="/assets/aspen.svg" alt="Aspen logo" className="w-24" />
        </Link>
        {showBadge && (
          <div
            className={`${getBadgeClass()} ml-16 rounded p-1 font-extrabold text-white`}
          >
            {import.meta.env.VITE_SENTRY_ENV}
          </div>
        )}
      </div>
      <nav className="flex h-full flex-col overflow-auto sm:pt-4">
        <div className="flex h-full flex-col">
          <div className="mb-4 border-0 border-b border-b-[#ffffff19] pb-4">
            <Link
              key="back"
              to="/"
              className="flex items-center px-[6px] py-[10px] text-sm text-brand-300"
            >
              <ChevronLeftIcon className="mr-[4px]" />
              Back to Work Queue
            </Link>
          </div>
          <div className="overflow-auto px-2">
            {ROUTES.map(({ to, description, icon, hideIf }) => {
              if (hideIf(selectedLines, subtype)) {
                return null;
              }
              const active = location.pathname.includes(to);
              const cn = `group flex px-[6px] py-[12px] text-sm font-semibold text-brand-0 ${active ? 'rounded-[8px] bg-brand-0 text-brand-700' : ''}`;

              return (
                <Link key={description} to={to} className={cn}>
                  {icon}
                  {description}
                </Link>
              );
            })}
          </div>
        </div>
        <Profile />
        <div className="mb-4 mt-auto flex justify-center">
          <img
            src="/assets/powered_by_counterpart.png"
            alt="Powered by Counterpart"
            className="w-[64%]"
          />
        </div>
      </nav>
    </aside>
  );
};

export default Navigation;

import {
  createContext,
  useState,
  Suspense,
  useEffect,
  useCallback,
} from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import Sidebar from './Sidebar';
import { ThemeProvider } from '@/lib/components/theme-provider';
import { Toaster } from '@/components/ui/toaster';
import Loader from '../components/Loader';
import Footer from './Footer';
import Header from './Header';
import Meta from './Meta';
import { useCurrentUserData } from '@/lib/utils/hooks';
import { useQuery } from '@tanstack/react-query';
import { glacierPolicyApiGetPolicyPermissionsOptions } from '../heyapi/@tanstack/react-query.gen';

export type userRole =
  | 'underwriter'
  | 'agent'
  | 'underwriting_admin'
  | 'system_admin';

export const userRoleEnum = {
  UNDERWRITER: 'underwriter',
  AGENT: 'agent',
  UNDERWRITING_ADMIN: 'underwriting_admin',
  SYSTEM_ADMIN: 'system_admin',
} as const;

export const permissionGroupEnum = {
  AGENT: 'AGENT',
  UNDERWRITER: 'UNDERWRITER',
  UNDERWRITING_ADMIN: 'UNDERWRITING_ADMIN',
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
} as const;

export type PermissionGroup =
  (typeof permissionGroupEnum)[keyof typeof permissionGroupEnum];

export function hasPermission(group: PermissionGroup, role: userRole) {
  if (role === userRoleEnum.SYSTEM_ADMIN) {
    return true;
  }

  if (group === permissionGroupEnum.AGENT) {
    return role === userRoleEnum.AGENT;
  }

  if (group === permissionGroupEnum.UNDERWRITER) {
    return (
      role === userRoleEnum.UNDERWRITER ||
      role === userRoleEnum.UNDERWRITING_ADMIN
    );
  }

  if (group === permissionGroupEnum.UNDERWRITING_ADMIN) {
    return role === userRoleEnum.UNDERWRITING_ADMIN;
  }

  return false;
}

export const PermissionsContext = createContext<{
  editMode: boolean;
  setEditMode: (arg0: boolean) => void;
  role: userRole;
  hasPermission: (group: PermissionGroup) => boolean;
  hasExactRole: (exactRole: userRole) => boolean;
  isSystemAdmin: () => boolean;
}>({
  editMode: false,
  setEditMode: () => {},
  role: userRoleEnum.AGENT,
  hasPermission: () => false,
  hasExactRole: () => false,
  isSystemAdmin: () => false,
});

const Layout = () => {
  const { id } = useParams();
  const { data } = useQuery({
    enabled: id != undefined,
    ...glacierPolicyApiGetPolicyPermissionsOptions({
      path: {
        account_id: id!,
      },
    }),
  });
  const currentUserData = useCurrentUserData();
  const [editMode, setEditMode] = useState<boolean>(true);
  const [role, setRole] = useState<userRole>(
    (currentUserData.currentUser.data?.role as userRole) || userRoleEnum.AGENT
  );

  const hasExactRole = useCallback(
    (exactRole: userRole) => role === exactRole,
    [role]
  );

  const isSystemAdmin = useCallback(
    () => role === userRoleEnum.SYSTEM_ADMIN,
    [role]
  );

  useEffect(() => {
    const roleVal =
      (currentUserData.currentUser.data?.role as userRole) ||
      userRoleEnum.AGENT;

    if (roleVal !== role) {
      setRole(roleVal);
    }
  }, [currentUserData.currentUser.data?.role, role]);

  useEffect(() => {
    if (data && (data == 'READ' || data == 'UNAUTHORIZED')) {
      setEditMode(false);
    }
    if (data && data == 'WRITE') {
      setEditMode(true);
    }

    // TODO: Add block if unauthorized & reroute to main screen
  }, [data]);

  const hasPermissionWrapper = useCallback(
    (group: PermissionGroup) => hasPermission(group, role),
    [role]
  );

  return (
    <ThemeProvider>
      <PermissionsContext.Provider
        value={{
          editMode,
          setEditMode,
          role,
          hasPermission: hasPermissionWrapper,
          hasExactRole,
          isSystemAdmin,
        }}
      >
        <Meta />
        <div className="flex flex-col dark:bg-black dark:text-white">
          <Header />
          <div className="flex flex-col pl-[20vw]">
            <Sidebar />
            <main className="wrapper mx-auto w-full max-w-full pt-0">
              {' '}
              {/* w-[93%] */}
              {/* TODO: See if shads class "wrapper" is better */}
              {/* TODO: Add fallback component */}
              <Suspense fallback={<Loader />}>
                <Outlet />
              </Suspense>
            </main>
            {/* <Button
              onClick={() => setEditMode((s) => !s)}
              className="fixed bottom-8 right-32 ml-auto mt-auto bg-brand-yellow text-brand-0 transition hover:bg-brand-yellow hover:opacity-70 hover:duration-300"
            >
              {editMode ? 'Read mode' : 'Edit mode'}
            </Button> */}
            <Footer />
            <Toaster />
          </div>
        </div>
      </PermissionsContext.Provider>
    </ThemeProvider>
  );
};

export default Layout;

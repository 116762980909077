import { useContext } from 'react';
import type { Control, FieldValues, Path } from 'react-hook-form';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import { getMappedOptions } from '../../helpers';

import { ReadOnlyField } from '@/lib/pages/forms/components';

import { PermissionsContext } from '@/lib/layout';

const CustomSelect = ({
  control,
  options,
  name,
  label,
  placeholder,
  disabled,
  orientation = 'vertical',
}: {
  // TODO: Fix any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  options: string[];
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  orientation?: 'horizontal' | 'vertical';
}) => {
  // console.log(placeholder);
  const mappedOptions = getMappedOptions(options);
  // console.log(mappedOptions, options);

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem
          className={`flex ${orientation === 'horizontal' ? 'items-center justify-between' : 'w-full flex-col'}`}
        >
          <FormLabel
            className={`${orientation === 'horizontal' ? 'w-1/3' : 'mb-2'} font-semibold leading-[1.5rem]`}
          >
            {label}
          </FormLabel>
          <div
            className={`${orientation === 'horizontal' ? 'w-1/3' : ''} !mt-0`}
          >
            <Select
              onValueChange={field.onChange}
              defaultValue={field.value}
              value={field.value}
              disabled={disabled}
            >
              <FormControl tabIndex={0}>
                <SelectTrigger tabIndex={0}>
                  <SelectValue placeholder={placeholder} />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {mappedOptions.map(({ value, label: optionLabel }) => (
                  <SelectItem key={value} value={value}>
                    {optionLabel}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <FormMessage />
          </div>
        </FormItem>
      )}
    />
  );
};

// TODO: Figure out where it was used as Select and rename export/import
export { CustomSelect as Selects };

export type Selectable<T extends string> = {
  value: T;
  label: string;
};

export const AlternativeSelect = <T extends FieldValues, U extends string>({
  control,
  options,
  name,
  label,
  placeholder,
  disabled,
  orientation = 'vertical',
  required,
  stretch = false,
  wideLabel,
}: {
  // TODO: Fix any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<T>;
  options: Array<Selectable<U>>;
  name: Path<T>;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  orientation?: 'horizontal' | 'vertical';
  required?: boolean | undefined;
  stretch?: boolean;
  wideLabel?: boolean;
}) => {
  // console.log(placeholder);
  // console.log(mappedOptions, options);
  const { editMode } = useContext(PermissionsContext);

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => {
        if (!editMode) {
          const optionLabel =
            (options || []).find((option) => option.value === field.value)
              ?.label || '';
          return (
            <ReadOnlyField
              label={label}
              value={optionLabel}
              orientation={orientation}
              stretch={stretch}
              wideLabel={wideLabel}
            />
          );
        } else {
          return (
            <FormItem
              className={`flex ${orientation === 'horizontal' ? 'items-center gap-4' : 'w-full flex-col'}`}
            >
              {label && (
                <FormLabel
                  className={`${orientation === 'horizontal' ? `${stretch ? `${wideLabel ? 'w-2/3' : 'w-1/3'}` : `${wideLabel ? 'w-2/5' : 'w-1/5'}`}` : 'mb-2'} font-semibold leading-[1.5rem]`}
                >
                  {label}
                  {required && <span className="text-red-400">*</span>}
                </FormLabel>
              )}
              <div
                className={`${orientation === 'horizontal' ? `${stretch ? `${wideLabel ? 'w-1/3' : 'w-2/3'}` : `${wideLabel ? 'w-1/5' : 'w-2/5'}`}` : ''} !mt-0`}
              >
                <Select
                  onValueChange={field.onChange}
                  value={field.value || undefined}
                  disabled={disabled}
                  required={required}
                >
                  <FormControl className="items-start overflow-clip px-[10px] [&>span]:text-start">
                    <SelectTrigger tabIndex={0}>
                      <SelectValue placeholder={placeholder} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {required
                      ? options.map(({ value, label }, idx) => (
                          <SelectItem
                            key={`${name}_${value}_${idx}`}
                            value={value}
                            className="min-h-6"
                          >
                            {label}
                          </SelectItem>
                        ))
                      : [{ value: null, label: '' }, ...options].map(
                          ({ value, label }, idx) => (
                            <SelectItem
                              key={`${name}_${value}_${idx}`}
                              value={value}
                              className="min-h-6"
                            >
                              {label}
                            </SelectItem>
                          )
                        )}
                  </SelectContent>
                </Select>
                <FormMessage />
              </div>
            </FormItem>
          );
        }
      }}
    />
  );
};

export { AlternativeSelect as Select };

import { useContext } from 'react';
import type { Control, FieldValues, Path } from 'react-hook-form';

import {
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';

import { Checkbox } from '@/components/ui/checkbox';

import { PermissionsContext } from '@/lib/layout';

function CustomCheckbox<T extends FieldValues>({
  control,
  name,
  label,
  disabled = false,
  direction = 'row',
  required = false,
}: {
  control: Control<T>;
  name: Path<T>;
  label: string;
  disabled?: boolean;
  direction: 'row' | 'reverse';
  required?: boolean;
}) {
  const { editMode } = useContext(PermissionsContext);
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem
          className={`flex items-center ${direction === 'reverse' ? 'flex-row-reverse' : ''}`}
        >
          <FormLabel
            htmlFor={name}
            className="mx-2 leading-[1.5rem]"
            style={{ fontWeight: 'inherit' }}
          >
            {label}
            {required && <span className="text-red-400">*</span>}
          </FormLabel>
          <Checkbox
            className={`!mt-0 ${!editMode ? '!opacity-100 data-[state=checked]:border-brand-600 data-[state=checked]:bg-brand-600' : 'data-[state=checked]:border-brand-green data-[state=checked]:bg-brand-green'}`}
            id={name}
            checked={field.value}
            onCheckedChange={field.onChange}
            disabled={disabled || !editMode}
          />
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export { CustomCheckbox as Checkbox };

export const formatter = new Intl.DateTimeFormat('en-US', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
});

export function formatDate(d: string): string {
  // This method sucks to have. For some reason, we are getting different formatted strings back
  // from the backend. SOMETIMES it is hyphenated (seems to be wherever we SET the date like
  // on Policy Details.
  // Other spots, like Location Effective Date, comes back formatted correctly by default

  try {
    let splitByHyphen = d.split('-');
    let splitBySlash = d.split('/');

    if (splitBySlash.length == 3) {
      return d;
    }

    if (splitByHyphen.length == 3) {
      return [splitByHyphen[1], splitByHyphen[2], splitByHyphen[0]].join('/');
    }
  } catch (Error) {
    return d;
  }

  return d;
}

export function toDateString(d: Date | string): string {
  try {
    let newDate = new Date(d);
    const offset = newDate.getTimezoneOffset();
    newDate = new Date(newDate.getTime() - offset * 60 * 1000);
    return newDate.toISOString().split('T')[0];
  } catch (e) {
    return '';
  }
}

export function toDateTimeString(d: Date | string): string {
  try {
    const date = new Date(d);
    return new Intl.DateTimeFormat('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'America/New_York',
      timeZoneName: 'short',
    })
      .format(date)
      .replace(', ', ' ');
  } catch (e) {
    return '';
  }
}

export function formatDateWithSlashes(
  date: Date | string | null | undefined
): string {
  if (!date) return '';
  try {
    return formatter.format(new Date(date));
  } catch (e) {
    return '';
  }
}

/**
 * Format date with proper timezone handling to prevent date shifting.
 */
export function formatDateWithSlashesNoShift(
  date: Date | string | null | undefined
): string {
  if (!date) return '';
  try {
    // Parse date and adjust for timezone to prevent date shifting
    const parsedDate = new Date(date);
    // Create a UTC date to avoid timezone conversion
    const utcDate = new Date(
      Date.UTC(
        parsedDate.getFullYear(),
        parsedDate.getMonth(),
        parsedDate.getDate()
      )
    );
    return formatter.format(utcDate);
  } catch (e) {
    return '';
  }
}

import { Link } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../../../components/ui/card';
import { Suspense } from 'react';
import Loader from '../../components/Loader';

export default function BaseLogin({
  renderComponent,
  title,
  description,
}: {
  renderComponent: JSX.Element;
  title: string;
  description: string;
}) {
  return (
    <div className="bg-brand-dark-green pt-9">
      <div className="flex min-h-[10vh] pl-4">
        <Link to="/" key="Home" className="ml-[16px]">
          <img src="/assets/aspen.svg" alt="Aspen logo" />
        </Link>
      </div>

      <div className="grid min-h-[90vh] items-center justify-center gap-2 bg-planets text-white">
        <Card className="w-[380px]">
          <CardHeader className="text-center">
            <CardTitle>{title}</CardTitle>
            <CardDescription>{description}</CardDescription>
          </CardHeader>
          <CardContent>
            <Suspense fallback={<Loader />}>{renderComponent}</Suspense>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

import { useState, useEffect, useMemo, useContext } from 'react';
import { LoaderCircle, XIcon } from 'lucide-react';
import { Separator } from '@/components/ui/separator';
import { Check, ChevronsUpDown, Copy } from 'lucide-react';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

import {
  glacierPolicyApiGetPolicyQueryKey,
  glacierPolicyApiGetFormulasQueryKey,
  glacierPolicyApiGetPolicySummaryQueryKey,
  glacierPolicyApiGetDentistQueryKey,
  glacierPolicyApiGetSpecialFormsMutation,
  glacierPolicyApiAssignableOptions,
  glacierPolicyApiAssignMutation,
  glacierPolicyApiGetPolicyPermissionsQueryKey,
  glacierPolicyFormsApiPolicyLevelAttachedEndorsementsQueryKey,
  glacierPolicyFormsApiGetAvailableEndorsementsQueryKey,
  glacierPolicyFormsApiGetDentistAttachedFormsQueryKey,
  glacierPolicyFormsApiGetDentistAvailableEndorsementsQueryKey,
  glacierPolicyFormsApiGetLocationAvailableEndorsementsQueryKey,
  glacierPolicyFormsApiGetLocationAttachedFormsQueryKey,
  glacierPolicyFormsApiGetEntityAvailableEndorsementsQueryKey,
  glacierPolicyFormsApiGetEntityAttachedFormsQueryKey,
  glacierPolicyApiListDocumentsQueryKey,
  glacierPolicyApiGetPrintQueueQueryKey,
  glacierPolicyApiDeclineTransactionMutation,
  glacierPolicyApiDiscardTransactionMutation,
  glacierPolicyApiReopenTransactionMutation,
  glacierPolicyApiIssuePolicyMutation,
  glacierPolicyApiCommitTransactionTestMutation,
} from '@/lib/heyapi/@tanstack/react-query.gen';

import type { Policy, Transaction, UserSchema2 } from '../heyapi';
import {
  QuoteTypeToLabel,
  PolicyTypeToLabel,
  TransactionStatusToLabel,
} from '@/lib/pages/forms/fields';
import { formatDate, toDateString } from '../utils/dates';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  glacierPolicyApiGetPolicyOptions,
  glacierPolicyApiRatePolicyMutation,
} from '../heyapi/@tanstack/react-query.gen';

import {
  PolicyDetails,
  TransactionStatus,
  TransactionType,
} from '@/lib/heyapi/types.gen';

import { toast } from '@/components/ui/use-toast';
import { formatCurrency } from '../utils/currency';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { RelatedUsersData, useRelatedUsersData } from '../utils/hooks';
import { useQueue } from '@uidotdev/usehooks';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Checkbox, Select } from '@/lib/pages/forms/components';
import { Form } from '@/components/ui/form';
import { PermissionsContext, userRoleEnum } from '@/lib/layout';
import { Selectable } from '../pages/forms/components/Select';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { BoxIcon } from '@radix-ui/react-icons';

const Header = () => {
  const { id } = useParams();

  const { data } = useQuery({
    ...glacierPolicyApiGetPolicyOptions({
      path: {
        account_id: id!,
      },
    }),
  });

  return <HeaderView policy={data} />;
};

type BackendRateError = {
  path: string[];
  attribute: string;
  code: string;
  message: string;
};
type BackendRateErrors = {
  errors: BackendRateError[];
};

function isBackendRatingError(error: unknown): error is BackendRateErrors {
  return (
    typeof error === 'object' &&
    error !== null &&
    'errors' in error &&
    Array.isArray((error as BackendRateErrors).errors) &&
    (error as BackendRateErrors).errors.length > 0
  );
}

function isOtherRatingError(error: unknown): error is BackendRateError {
  return typeof error === 'object' && error !== null && 'path' in error;
}

function formattedPathAndMessage(error: BackendRateError): [string, string] {
  const entityPath = error.path.slice(0, -1).join(' -> '); // Exclude the last part (attribute)
  let message = error.message;

  if (error.attribute != '') {
    message = `'${error.attribute}' is required`;
  }

  return [entityPath, message];
}

function formatError(error: BackendRateError): string {
  const [entityPath, message] = formattedPathAndMessage(error);
  return `${entityPath} ${message}`;
}

function formatErrors(errors: BackendRateErrors): string {
  const errorMap: Record<string, string[]> = {};

  errors.errors.forEach((error) => {
    const [entityPath, message] = formattedPathAndMessage(error);

    if (!errorMap[entityPath]) {
      errorMap[entityPath] = [];
    }
    errorMap[entityPath].push(message);
  });

  let formattedErrors = '';

  Object.keys(errorMap).forEach((entityPath) => {
    formattedErrors += `\n${entityPath}:\n`;
    errorMap[entityPath].forEach((message) => {
      formattedErrors += `    - ${message}\n`;
    });
  });

  return formattedErrors;
}

const DebugTransaction = ({ transaction }: { transaction: Transaction }) => {
  const [copiedRequest, setCopiedRequest] = useState(false);
  const [copiedResponse, setCopiedResponse] = useState(false);

  const copyToClipboard = (
    text: string,
    setCopied: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  if (transaction.latestAttempt) {
    const { request, response } = transaction.latestAttempt;

    return (
      <div className="max-w-screen grid h-[80vh] w-full grid-cols-2 gap-4">
        <Card className="overflow-scroll">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle>Request to Rater</CardTitle>
            <Button
              variant="outline"
              size="icon"
              onClick={() =>
                copyToClipboard(
                  JSON.stringify(request, null, 2),
                  setCopiedRequest
                )
              }
              aria-label="Copy request JSON"
            >
              {copiedRequest ? (
                <Check className="h-4 w-4" />
              ) : (
                <Copy className="h-4 w-4" />
              )}
            </Button>
          </CardHeader>
          <CardContent>
            <code>
              {JSON.stringify(transaction.latestAttempt?.request, null, 2)}
            </code>
          </CardContent>
        </Card>

        <Card className="overflow-scroll">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle>Response from Rater</CardTitle>
            <Button
              variant="outline"
              size="icon"
              onClick={() =>
                copyToClipboard(
                  JSON.stringify(response, null, 2),
                  setCopiedResponse
                )
              }
              aria-label="Copy request JSON"
            >
              {copiedResponse ? (
                <Check className="h-4 w-4" />
              ) : (
                <Copy className="h-4 w-4" />
              )}
            </Button>
          </CardHeader>
          <CardContent>
            <code>
              {JSON.stringify(transaction.latestAttempt?.response, null, 2)}
            </code>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <p>
      No rating has been recorded yet. When you see "Policy Rated" or "Error
      While Rating Policy", come back here
    </p>
  );
};

const Transfer = ({ accountId }: { accountId: string }) => {
  const { data } = useQuery({
    ...glacierPolicyApiAssignableOptions({
      path: {
        account_id: accountId,
      },
    }),
  });
  return <TransferView accountId={accountId} related={data || []} />;
};

type TransferQuote = {
  id: string;
};

const TransferView = ({
  related,
  accountId,
}: {
  accountId: string;
  related: Array<UserSchema2>;
}) => {
  const qc = useQueryClient();
  const permissionsQk = glacierPolicyApiGetPolicyPermissionsQueryKey({
    path: { account_id: accountId },
  });
  const summariesQk = glacierPolicyApiGetPolicySummaryQueryKey();
  const policyQk = glacierPolicyApiGetPolicyQueryKey({
    path: {
      account_id: accountId,
    },
  });

  const transfer = useMutation({
    ...glacierPolicyApiAssignMutation(),
    onSuccess(data, variables, context) {
      qc.refetchQueries({ queryKey: permissionsQk });
      qc.refetchQueries({ queryKey: summariesQk });
      qc.refetchQueries({ queryKey: policyQk });
      toast({
        title: 'Success',
        description: (
          <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
            <code className="text-white">Transferred</code>
          </pre>
        ),
      });
    },
  });

  const form = useForm<TransferQuote>({
    defaultValues: {
      id: undefined,
    },
  });

  const { control, handleSubmit } = form;

  const selectable = related.map((u) => ({
    label: `${u.firstName} ${u.lastName} <${u.email}>`,
    value: String(u.id!),
  }));

  const onSubmit: SubmitHandler<TransferQuote> = (data) => {
    transfer.mutate({
      path: {
        account_id: accountId,
      },
      body: {
        userId: Number(data.id),
      },
    });
  };
  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="overflow-scroll">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle>Transfer</CardTitle>
          </CardHeader>
          <CardContent className="pt-4">
            <Select
              control={control}
              name="id"
              options={selectable}
              label=""
              placeholder="Choose an Agent or Underwriter"
            />
          </CardContent>
          <CardFooter className="pt-4">
            <Button
              type="submit"
              className="fixed bottom-8 right-10 ml-auto mt-auto bg-brand-green text-brand-0 transition hover:bg-brand-green hover:opacity-70 hover:duration-300"
            >
              Transfer
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
};

const DECLINE_OPTIONS: Array<Selectable<string>> = [
  'Pricing/competition',
  'Coverage',
  'No response to request for information',
  'Loyalty to incumbent broker/carrier',
].map((d) => ({ label: d, value: d }));

type DeclineForm = {
  option: string;
};

const Decline = ({
  accountId,
  title,
}: {
  accountId: string;
  title: string;
}) => {
  const qc = useQueryClient();
  const permissionsQk = glacierPolicyApiGetPolicyPermissionsQueryKey({
    path: { account_id: accountId },
  });
  const summariesQk = glacierPolicyApiGetPolicySummaryQueryKey();
  const policyQk = glacierPolicyApiGetPolicyQueryKey({
    path: {
      account_id: accountId,
    },
  });

  const transfer = useMutation({
    ...glacierPolicyApiDeclineTransactionMutation(),
    onSuccess(data, variables, context) {
      qc.refetchQueries({ queryKey: permissionsQk });
      qc.refetchQueries({ queryKey: summariesQk });
      qc.refetchQueries({ queryKey: policyQk });
      toast({
        title: 'Success',
        description: (
          <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
            <code className="text-white">{title}</code>
          </pre>
        ),
      });
    },
  });

  const form = useForm<DeclineForm>({
    defaultValues: {
      option: undefined,
    },
  });

  const { control, handleSubmit } = form;

  const onSubmit: SubmitHandler<DeclineForm> = (data) => {
    transfer.mutate({
      path: {
        account_id: accountId,
      },
      body: {
        reason: data.option,
      },
    });
  };
  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="overflow-scroll">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle>{title}</CardTitle>
          </CardHeader>
          <CardContent className="pt-4">
            <Select
              control={control}
              name="option"
              options={DECLINE_OPTIONS}
              label=""
              placeholder="Choose reason"
            />
          </CardContent>
          <CardFooter className="pt-4">
            <Button
              type="submit"
              className="fixed bottom-8 right-10 ml-auto mt-auto bg-brand-green text-brand-0 transition hover:bg-brand-green hover:opacity-70 hover:duration-300"
            >
              Submit
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
};

type DiscardForm = {
  confirm: boolean;
};

const Discard = ({ accountId }: { accountId: string }) => {
  const qc = useQueryClient();
  const permissionsQk = glacierPolicyApiGetPolicyPermissionsQueryKey({
    path: { account_id: accountId },
  });
  const summariesQk = glacierPolicyApiGetPolicySummaryQueryKey();
  const policyQk = glacierPolicyApiGetPolicyQueryKey({
    path: {
      account_id: accountId,
    },
  });

  const navigate = useNavigate();

  const transfer = useMutation({
    ...glacierPolicyApiDiscardTransactionMutation(),
    onSuccess(data, variables, context) {
      qc.refetchQueries({ queryKey: permissionsQk });
      qc.refetchQueries({ queryKey: summariesQk });
      qc.refetchQueries({ queryKey: policyQk });
      toast({
        title: 'Success',
        description: (
          <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
            <code className="text-white">Discarded</code>
          </pre>
        ),
      });
      navigate('/');
    },
  });

  const form = useForm<DiscardForm>({
    defaultValues: {
      confirm: false,
    },
  });

  const { control, handleSubmit } = form;

  const onSubmit: SubmitHandler<DiscardForm> = (data) => {
    transfer.mutate({
      path: {
        account_id: accountId,
      },
    });
  };
  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="overflow-scroll">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle>Discard Transaction</CardTitle>
          </CardHeader>
          <CardContent className="pt-4">
            <Checkbox
              control={control}
              label="Discarding the transaction will remove it from view. Please confirm your intention."
              name="confirm"
              direction="reverse"
            />
          </CardContent>
          <CardFooter className="pt-4">
            <Button
              type="submit"
              className="fixed bottom-8 right-10 ml-auto mt-auto bg-brand-green text-brand-0 transition hover:bg-brand-green hover:opacity-70 hover:duration-300"
              variant="destructive"
            >
              Submit
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
};

const Reopen = ({ accountId }: { accountId: string }) => {
  const qc = useQueryClient();
  const permissionsQk = glacierPolicyApiGetPolicyPermissionsQueryKey({
    path: { account_id: accountId },
  });
  const summariesQk = glacierPolicyApiGetPolicySummaryQueryKey();
  const policyQk = glacierPolicyApiGetPolicyQueryKey({
    path: {
      account_id: accountId,
    },
  });

  const transfer = useMutation({
    ...glacierPolicyApiReopenTransactionMutation(),
    onSuccess(data, variables, context) {
      qc.refetchQueries({ queryKey: permissionsQk });
      qc.refetchQueries({ queryKey: summariesQk });
      qc.refetchQueries({ queryKey: policyQk });
      toast({
        title: 'Success',
        description: (
          <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
            <code className="text-white">Discarded</code>
          </pre>
        ),
      });
    },
  });

  const form = useForm({});

  const { handleSubmit } = form;

  const onSubmit = (data: any) => {
    transfer.mutate({
      path: {
        account_id: accountId,
      },
    });
  };
  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="overflow-scroll">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle>Reopen Transaction</CardTitle>
          </CardHeader>
          <CardContent className="pt-4">
            Click Submit to Reopen the Transaction
          </CardContent>
          <CardFooter className="pt-4">
            <Button
              type="submit"
              className="fixed bottom-8 right-10 ml-auto mt-auto bg-brand-green text-brand-0 transition hover:bg-brand-green hover:opacity-70 hover:duration-300"
            >
              Submit
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
};

const TransactionMessage = ({ tx }: { tx: Transaction }) => {
  return null;

  // BRING BACK LATER
  return (
    <div className="w-full bg-brand-yellow px-10 py-1 leading-[1.5rem] text-[white]">
      <strong>Status Message: </strong>
      <span>{tx?.statusMessage || 'None'}</span>
    </div>
  );
};

type Message = {
  content: string;
  level: 'INFO' | 'ERROR';
};

const StandardMessageHeader = ({
  message,
}: {
  message: Message | undefined;
}) => {
  if (!message) return null;

  return (
    <div
      className={`w-full ${message.level === 'INFO' ? 'bg-amber-500' : 'bg-red-500'} px-10 py-1 leading-[1.5rem] text-white`}
    >
      <strong>Status Message: </strong>
      {message.content}
    </div>
  );
};

const ErrorDialog = ({
  title,
  children,
  onClose,
}: {
  title: string;
  children: React.ReactNode;
  onClose: () => void;
}) => {
  return (
    <div className="pointer-events-auto fixed inset-0 z-[9999] flex items-center justify-center overflow-y-auto bg-black/50 p-4">
      <div className="my-8 w-full max-w-2xl rounded-lg bg-white shadow-xl">
        <div className="p-6">
          <div className="flex items-center justify-between border-b pb-3">
            <h2 className="text-xl font-semibold text-red-600">{title}</h2>
            <button
              onClick={onClose}
              type="button"
              className="text-gray-400 hover:text-gray-600"
            >
              <XIcon className="h-5 w-5" />
            </button>
          </div>
          <div className="mt-4 max-h-[50vh] overflow-y-auto pr-2">
            {children}
          </div>
          <div className="mt-6 flex justify-end border-t pt-4">
            {/* <p className="text-red-600 mr-auto">Policy Issuance DID NOT Occur</p> */}
            <button
              onClick={onClose}
              type="button"
              className="rounded-lg bg-red-600 px-4 py-2 text-white transition-colors hover:bg-red-700"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const formatErrorMessage = (
  error: any,
  setMessage: (msg: Message | undefined) => void
) => {
  try {
    if (error.errors && Array.isArray(error.errors)) {
      return (
        <div className="space-y-4">
          {error.errors.map((err: BackendRateError, idx: number) => {
            const [entityPath, message] = formattedPathAndMessage(err);
            return (
              <div
                key={idx}
                className="flex items-start gap-3 rounded-lg bg-red-50 p-3"
              >
                <ExclamationTriangleIcon className="mt-0.5 h-5 w-5 flex-shrink-0 text-red-500" />
                <div>
                  <p className="font-medium text-red-700">{entityPath}</p>
                  <p className="text-red-600">{message}</p>
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    const errorDetail = error.detail;

    if (!errorDetail.includes('messages=[') && errorDetail.includes('Error(')) {
      const title = errorDetail.split(':')[0];
      const errorsStr = errorDetail.split(':')[1].trim();
      const errors = errorsStr
        .slice(1, -1)
        .split('), Error(')
        .map((err: any) => {
          const messageMatch = err.match(/message='([^']+)'/);
          return {
            message: messageMatch ? messageMatch[1] : '',
          };
        });

      return (
        <div className="space-y-4">
          {errors.map((err: any, idx: any) => (
            <div
              key={idx}
              className="flex items-start gap-3 rounded-lg bg-red-50 p-3"
            >
              <ExclamationTriangleIcon className="mt-0.5 h-5 w-5 flex-shrink-0 text-red-500" />
              <div>
                <p className="font-medium text-red-700">{err.message}</p>
              </div>
            </div>
          ))}
        </div>
      );
    }

    // Generic Error
    return (
      <div className="flex items-start gap-3 rounded-lg bg-red-50 p-3">
        <ExclamationTriangleIcon className="mt-0.5 h-5 w-5 flex-shrink-0 text-red-500" />
        <p className="font-medium text-red-700">{errorDetail}</p>
      </div>
    );
  } catch (e) {
    return error.detail || error.toString();
  }
};

const HeaderView = ({ policy }: { policy: Policy | undefined }) => {
  const queryClient = useQueryClient();
  const { editMode, role } = useContext(PermissionsContext);

  const { id, dentistId, locationId, entityId } = useParams();
  const policy_qk = glacierPolicyApiGetPolicyQueryKey({
    path: {
      account_id: id!,
    },
  });

  const formulas_qk = glacierPolicyApiGetFormulasQueryKey({
    path: {
      account_id: id!,
    },
  });

  const summaryKey = glacierPolicyApiGetPolicySummaryQueryKey();

  const dentistQK = glacierPolicyApiGetDentistQueryKey({
    path: {
      account_id: id!,
      dentist_id: dentistId || '',
    },
  });

  const policyLevelAttachedFQK =
    glacierPolicyFormsApiPolicyLevelAttachedEndorsementsQueryKey({
      path: {
        account_id: id!,
      },
    });

  const policyLevelAvailableFQK =
    glacierPolicyFormsApiGetAvailableEndorsementsQueryKey({
      path: {
        account_id: id!,
      },
    });

  const getDentistAttachedFQK =
    glacierPolicyFormsApiGetDentistAttachedFormsQueryKey({
      path: {
        account_id: id!,
        dentist_id: dentistId!,
      },
    });

  const getDentistAvailableFQK =
    glacierPolicyFormsApiGetDentistAvailableEndorsementsQueryKey({
      path: {
        dentist_id: dentistId!,
        account_id: id!,
      },
    });

  const getEntityAttachedFQK =
    glacierPolicyFormsApiGetEntityAttachedFormsQueryKey({
      path: {
        entity_id: entityId!,
        account_id: id!,
      },
    });

  const getEntityAvailableFQK =
    glacierPolicyFormsApiGetEntityAvailableEndorsementsQueryKey({
      path: {
        entity_id: entityId!,
        account_id: id!,
      },
    });

  const locationAttachedFQK =
    glacierPolicyFormsApiGetLocationAttachedFormsQueryKey({
      path: {
        location_id: locationId!,
        account_id: id!,
      },
    });

  const locationAvailableFQK =
    glacierPolicyFormsApiGetLocationAvailableEndorsementsQueryKey({
      path: {
        location_id: locationId!,
        account_id: id!,
      },
    });

  const printQueueKey = glacierPolicyApiGetPrintQueueQueryKey();

  const [loading, setLoading] = useState(false);
  const [issuing, setIssuing] = useState(false);
  const [forming, setForming] = useState(false);
  const [message, setMessage] = useState<Message | undefined>(undefined);
  const [dialogContent, setDialogContent] = useState<
    | 'RATING'
    | 'TRANSFER'
    | 'DISCARD'
    | 'DECLINE'
    | 'NOT_TAKEN'
    | 'REOPEN'
    | undefined
  >(undefined);

  const [expanded, setExpanded] = useState(
    localStorage.getItem('expanded') === 'true'
  );

  const [submissionError, setSubmissionError] = useState<string | null>(null);

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    localStorage.setItem('expanded', expanded.toString());
  }, [expanded]);

  const effectiveDate = useMemo(() => {
    return policy?.details?.policyEffectiveDate
      ? formatDate(policy.details?.policyEffectiveDate)
      : '--';
  }, [policy?.details?.policyEffectiveDate]);

  const transactionEffectiveDate = useMemo(() => {
    return policy?.latestTransaction?.effectiveDate
      ? toDateString(policy.latestTransaction.effectiveDate)
      : null;
  }, [policy?.latestTransaction?.effectiveDate]);

  const expirationDate = useMemo(() => {
    return policy?.details?.policyExpirationDate
      ? formatDate(policy.details?.policyExpirationDate)
      : '--';
  }, [policy?.details?.policyExpirationDate]);

  const firstNamedInsured = useMemo(() => {
    const { firstName, middleName, lastName, professionalDesignation } =
      policy?.details || ({} as PolicyDetails);

    let individualName = [firstName, middleName, lastName]
      .filter((v) => v)
      .join(' ');

    if (professionalDesignation) {
      individualName = `${individualName}, ${professionalDesignation.join(' ')}`;
    }

    return policy?.details?.entityName || individualName || '--';
  }, [
    policy?.details?.entityName,
    policy?.details?.firstName,
    policy?.details?.middleName,
    policy?.details?.lastName,
  ]);

  const assignedUser = useMemo(() => {
    return policy?.assignedTo?.firstName
      ? `${policy.assignedTo.firstName} ${policy.assignedTo.lastName}`
      : '--';
  }, [policy?.assignedTo?.firstName, policy?.assignedTo?.lastName]);

  const statusStage = useMemo(() => {
    const quoteType = policy?.details?.quoteType
      ? QuoteTypeToLabel[policy?.details?.quoteType]
      : policy?.details?.policyType
        ? PolicyTypeToLabel[policy?.details?.policyType]
        : '--';

    const latestTransaction = policy?.latestTransaction?.status
      ? TransactionStatusToLabel[policy.latestTransaction.status]
      : '--';

    return `${quoteType} / ${latestTransaction}`;
  }, [
    policy?.details?.quoteType,
    policy?.details?.policyType,
    policy?.latestTransaction?.status,
  ]);

  const issuePolicySpecimen = useMutation({
    ...glacierPolicyApiGetSpecialFormsMutation(),
    onSuccess(data, variables) {
      setIssuing(false);

      queryClient.refetchQueries({ queryKey: warehouseKey });
      setMessage({
        content: 'Policy Specimen generated. Check Document Warehouse',
        level: 'INFO',
      });
    },
    onError(error: any) {
      setIssuing(false);
      setForming(false);
      setMessage({
        content: 'Error generating the Policy Specimen',
        level: 'ERROR',
      });
    },
  });

  const warehouseKey = glacierPolicyApiListDocumentsQueryKey({
    path: { account_id: id! },
  });

  const quoteForm = useMutation({
    ...glacierPolicyApiGetSpecialFormsMutation(),
    onSuccess(data, variables) {
      setForming(false);
      queryClient.refetchQueries({ queryKey: warehouseKey });
      setMessage({
        content:
          'Quote Proposal generated. Check Document Warehouse. Generating Specimen',
        level: 'INFO',
      });
      issuePolicySpecimen.mutate({
        path: { account_id: policy!.pk! },
        query: { special_form: 'policy_specimen' },
      });

      setIssuing(true);
    },
    onError(error) {
      setForming(false);
      setIssuing(false);
      setMessage({
        content: 'Error generating the Quote Proposal',
        level: 'ERROR',
      });
    },
  });

  const lockTransactionAdmin = useMutation({
    ...glacierPolicyApiCommitTransactionTestMutation(),
    onSuccess(data, variables) {
      setLoading(false);
      toast({
        title: 'Success',
        description: (
          <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
            <code className="text-white">
              Transaction Locked! Reloading Data
            </code>
          </pre>
        ),
      });
      // Delay 2 seconds and reload the page
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
    onError(error: any) {
      setLoading(false);

      let title = 'Error: Transaction Lock Failed';
      const content = formatErrorMessage(error, setMessage);
      console.log('error', error);

      try {
        let jsonString = JSON.stringify(error);
        title = 'Error: Transaction Lock Failed: ' + jsonString;
      } catch (e) {
        console.log('error', e);
      }

      setMessage({
        content: (
          <ErrorDialog title={title} onClose={() => setMessage(undefined)}>
            {content}
          </ErrorDialog>
        ),
        level: 'ERROR',
      });
    },
  });

  const issuePolicy = useMutation({
    ...glacierPolicyApiIssuePolicyMutation(),
    onSuccess(data, variables) {
      setIssuing(false);
      queryClient.refetchQueries({ queryKey: warehouseKey });
      queryClient.refetchQueries({ queryKey: policy_qk });
      queryClient.refetchQueries({ queryKey: formulas_qk });
      queryClient.refetchQueries({ queryKey: summaryKey });
      queryClient.refetchQueries({ queryKey: printQueueKey });
      setMessage({
        content:
          'Policy has been issued. Check the Document Warehouse for the Bundle',
        level: 'INFO',
      });
    },
    onError(error: any) {
      setLoading(false);
      queryClient.invalidateQueries({ queryKey: policy_qk });
      queryClient.invalidateQueries({ queryKey: formulas_qk });

      let title = 'Missing required fields for policy issuance';
      const content = formatErrorMessage(error, setMessage);

      if (error.detail?.includes('Error(')) {
        title = error.detail.split(':')[0];
      }

      setMessage({
        content: (
          <ErrorDialog title={title} onClose={() => setMessage(undefined)}>
            {content}
          </ErrorDialog>
        ),
        level: 'ERROR',
      });
    },
  });

  const rate = useMutation({
    ...glacierPolicyApiRatePolicyMutation(),
    onSuccess(data, variables) {
      queryClient.setQueryData(
        glacierPolicyApiGetPolicyOptions({ ...variables }).queryKey,
        data
      );
      queryClient.refetchQueries({ queryKey: policy_qk });
      queryClient.refetchQueries({ queryKey: formulas_qk });
      queryClient.refetchQueries({ queryKey: summaryKey });
      queryClient.refetchQueries({ queryKey: dentistQK });

      queryClient.refetchQueries({ queryKey: policyLevelAttachedFQK });
      queryClient.refetchQueries({ queryKey: policyLevelAvailableFQK });
      queryClient.refetchQueries({ queryKey: getDentistAttachedFQK });
      queryClient.refetchQueries({ queryKey: getDentistAvailableFQK });
      queryClient.refetchQueries({ queryKey: getEntityAttachedFQK });
      queryClient.refetchQueries({ queryKey: getEntityAvailableFQK });
      queryClient.refetchQueries({ queryKey: locationAttachedFQK });
      queryClient.refetchQueries({ queryKey: locationAvailableFQK });
      setLoading(false);
      toast({
        title: 'Success',
        description: (
          <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
            <code className="text-white">Account Rated</code>
          </pre>
        ),
      });
    },
    onError(error: any) {
      setLoading(false);
      queryClient.invalidateQueries({ queryKey: policy_qk });
      queryClient.invalidateQueries({ queryKey: formulas_qk });

      let title = 'Missing required fields for policy issuance';

      if (error.errors && Array.isArray(error.errors)) {
        title = 'Missing required fields for rating';
      } else if (error.detail?.includes('Error(')) {
        title = error.detail.split(':')[0];
      }

      const content = formatErrorMessage(error, setMessage);

      setMessage({
        content: (
          <ErrorDialog title={title} onClose={() => setMessage(undefined)}>
            {content}
          </ErrorDialog>
        ),
        level: 'ERROR',
      });
    },
  });

  const policyBoundButTransactionInProgress = useMemo(() => {
    // Used to determine if the policy is bound but not locked
    // Will remove once we lock all accounts and launch midterms
    if (
      policy &&
      policy?.latestTransaction.status == TransactionStatus.IN_PROGRESS &&
      policy &&
      policy?.boundPolicyNumber != undefined &&
      policy?.boundPolicyNumber != null &&
      policy?.boundPolicyNumber.length > 0 &&
      policy?.latestTransaction.transactionType == TransactionType.POLICY_BOUND
    ) {
      return true;
    }
    return false;
  }, [policy?.latestTransaction.status, policy?.boundPolicyNumber]);

  const declinable = useMemo(() => {
    return policy?.latestTransaction.status == TransactionStatus.IN_PROGRESS;
  }, [policy?.latestTransaction]);

  const discardable = useMemo(() => {
    let policy_number_is_null =
      policy?.boundPolicyNumber == undefined ||
      policy?.boundPolicyNumber == null ||
      policy?.boundPolicyNumber == '';

    return (
      policy_number_is_null &&
      policy?.latestTransaction.status != TransactionStatus.DISCARDED
    );
  }, [policy, role]);

  const reopenable = useMemo(() => {
    return (
      policy?.latestTransaction.status == TransactionStatus.DECLINED ||
      policy?.latestTransaction.status == TransactionStatus.NOT_TAKEN
    );
  }, [policy?.latestTransaction, role]);

  return (
    <header className="bg-base-100/80 pointer-events-none sticky top-0 z-20 !text-[14px]">
      {/* backdrop-blur-md */}
      <section className="ml-[calc(20vw-1px)] flex flex-col items-center justify-between sm:text-xs 2xl:text-[14px]">
        <div className="pointer-events-auto mx-auto w-full overflow-hidden border-brand-200 bg-brand-100 pl-10">
          <div className="flex w-full justify-between gap-8">
            <div className="container flex w-10/12 justify-between">
              <div className="flex w-4/12 flex-col gap-1 py-4 pr-8">
                <div className="flex gap-4 text-sm">
                  <span className="block w-5/12">First Name Insured:</span>
                  <span
                    className="block w-7/12 overflow-hidden overflow-ellipsis whitespace-nowrap font-bold"
                    title={firstNamedInsured}
                  >
                    {firstNamedInsured}
                  </span>
                </div>
                <div className="flex gap-4 text-sm">
                  <span className="block w-5/12">Agency:</span>
                  <span
                    className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                    title={policy?.details?.agencyName || 'agencyName'}
                  >
                    {policy?.details?.agencyName || '--'}
                  </span>
                </div>
                {expanded && (
                  <>
                    <div className="flex gap-4 text-sm">
                      <span className="block w-5/12">Assigned User:</span>
                      <span
                        className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                        title={assignedUser}
                      >
                        {assignedUser}
                      </span>
                    </div>
                    <div className="flex gap-4 text-sm">
                      <span className="block w-5/12">
                        Change Effective Date:
                      </span>
                      <span
                        className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                        title={transactionEffectiveDate || ''}
                      >
                        {transactionEffectiveDate}
                      </span>
                    </div>
                  </>
                )}
              </div>

              <Separator className="my-4 h-auto" orientation="vertical" />

              <div className="flex w-4/12 flex-col gap-1 px-8 py-4">
                <div className="flex gap-4 text-sm">
                  <span className="block w-5/12">
                    <span>Quote Number:</span>
                  </span>
                  <span
                    className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                    title={policy?.policyNumber || ''}
                  >
                    {policy?.policyNumber}
                  </span>
                </div>
                <div className="flex gap-4 text-sm">
                  <span className="block w-5/12">Primary Practice State:</span>
                  <span
                    className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                    title={policy?.details?.policyContractState || ''}
                  >
                    {policy?.details?.policyContractState || '--'}
                  </span>
                </div>
                {expanded && (
                  <>
                    <div className="flex gap-4 text-sm">
                      <span className="block w-5/12">Status/Stage:</span>
                      <span
                        className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                        title={statusStage}
                      >
                        {statusStage}
                      </span>
                    </div>
                    <div className="flex gap-4 text-sm">
                      <span className="block w-5/12">Policy Period</span>
                      <span
                        className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                        title={`${effectiveDate} - ${expirationDate}`}
                      >
                        {effectiveDate} - {expirationDate}
                      </span>
                    </div>
                  </>
                )}
              </div>

              <Separator className="my-4 h-auto" orientation="vertical" />

              <div className="flex w-4/12 flex-col gap-1 px-8 py-4">
                <div className="flex gap-4 text-sm">
                  <span className="block w-5/12">
                    <span>Policy Number:</span>
                  </span>
                  <span
                    className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                    title={policy?.boundPolicyNumber || ''}
                  >
                    {policy?.boundPolicyNumber}
                  </span>
                </div>
                <div className="flex gap-4 text-sm">
                  <span className="block w-5/12">
                    <span>Transaction ID:</span>
                  </span>
                  <span
                    className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                    title={'Transaction ID'}
                  >
                    {policy?.transactionId || ''}
                  </span>
                </div>

                <div className="flex gap-4 text-sm">
                  <span className="block w-5/12">Billed Amount:</span>
                  <span
                    className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                    title="Billed Amount"
                  >
                    {(policy?.latestTransaction?.billedPremium || 0) +
                      (policy?.latestTransaction?.billedTax || 0) ===
                    0
                      ? ''
                      : formatCurrency(
                          (policy?.latestTransaction?.billedPremium || 0) +
                            (policy?.latestTransaction?.billedTax || 0)
                        )}
                  </span>
                </div>
                {policy &&
                  policy?.latestTransaction.status ==
                    TransactionStatus.ACTIVE && (
                    <div className="flex gap-4 text-sm">
                      <span className="block w-5/12">Policy Locked:</span>
                      <span
                        className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                        title="Billed Amount"
                        style={{ width: '15px' }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M144 144l0 48 160 0 0-48c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192l0-48C80 64.5 144.5 0 224 0s144 64.5 144 144l0 48 16 0c35.3 0 64 28.7 64 64l0 192c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 256c0-35.3 28.7-64 64-64l16 0z"></path>
                        </svg>
                      </span>
                    </div>
                  )}
                {expanded && (
                  <>
                    <div className="flex gap-4 text-sm">
                      <span className="block w-5/12">Written Amount:</span>
                      <span
                        className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                        title="Written Premium"
                      >
                        {(policy?.latestTransaction?.writtenPremium || 0) +
                          (policy?.latestTransaction?.writtenTax || 0) ===
                        0
                          ? ''
                          : formatCurrency(
                              (policy?.latestTransaction?.writtenPremium || 0) +
                                (policy?.latestTransaction?.writtenTax || 0)
                            )}
                      </span>
                    </div>
                    <div className="flex gap-4 text-sm">
                      <span className="block w-5/12">Annual Amount:</span>
                      <span
                        className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                        title="Annual Amount"
                      >
                        {(policy?.latestTransaction?.annualPremium || 0) +
                          (policy?.latestTransaction?.annualTax || 0) ===
                        0
                          ? ''
                          : formatCurrency(
                              (policy?.latestTransaction?.annualPremium || 0) +
                                (policy?.latestTransaction?.annualTax || 0)
                            )}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="flex w-2/12 justify-end">
              <Separator className="my-4 h-auto" orientation="vertical" />
              <div className="m-4 flex h-auto w-8/12 max-w-[100px] items-center justify-center">
                {loading && (
                  <div className="flex flex-row-reverse items-center gap-4">
                    <span className="text-sm">Rating</span>
                    <LoaderCircle className="h-10 w-10 animate-spin text-brand-200" />
                  </div>
                )}
                {!loading && (
                  <Dialog>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          type="button"
                          className="h-10 w-10 bg-[none] p-0 hover:bg-brand-200"
                        >
                          <DotsHorizontalIcon className="!hover:bg-[none] h-4 w-4 rounded-none text-[black]" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuItem onClick={() => {}} disabled={true}>
                          <span className="ml-2">Approve</span>
                        </DropdownMenuItem>
                        {role != userRoleEnum.AGENT && declinable && (
                          <DialogTrigger
                            asChild
                            onClick={() => setDialogContent('DECLINE')}
                          >
                            <DropdownMenuItem disabled={!editMode}>
                              <span className="ml-2">Decline</span>
                            </DropdownMenuItem>
                          </DialogTrigger>
                        )}
                        {role != userRoleEnum.AGENT && reopenable && (
                          <DialogTrigger
                            asChild
                            onClick={() => setDialogContent('REOPEN')}
                          >
                            <DropdownMenuItem>
                              <span className="ml-2">Reopen</span>
                            </DropdownMenuItem>
                          </DialogTrigger>
                        )}

                        {discardable && (
                          <DialogTrigger
                            asChild
                            onClick={() => setDialogContent('DISCARD')}
                          >
                            <DropdownMenuItem>
                              <span className="ml-2">Discard</span>
                            </DropdownMenuItem>
                          </DialogTrigger>
                        )}

                        {role == userRoleEnum.AGENT && declinable && (
                          <DialogTrigger
                            asChild
                            onClick={() => setDialogContent('NOT_TAKEN')}
                          >
                            <DropdownMenuItem disabled={!editMode}>
                              <span className="ml-2">Not Taken</span>
                            </DropdownMenuItem>
                          </DialogTrigger>
                        )}

                        <DialogTrigger
                          asChild
                          onClick={() => setDialogContent('TRANSFER')}
                        >
                          <DropdownMenuItem>
                            <span className="ml-2">Transfer</span>
                          </DropdownMenuItem>
                        </DialogTrigger>

                        <DropdownMenuItem
                          onClick={() => {
                            setForming(true);
                            setMessage({
                              content:
                                'Generating Specimen. Proposal & Bundle will be in Document Warehouse when complete',
                              level: 'INFO',
                            });
                            quoteForm.mutate({
                              path: { account_id: policy!.pk! },
                              query: { special_form: 'quote_proposal' },
                            });
                          }}
                          disabled={
                            policy?.canBeQuoted !== true ||
                            issuing ||
                            forming ||
                            !editMode
                          }
                        >
                          <span className="ml-2">
                            {issuing || forming
                              ? 'Generating Quote...'
                              : 'Generate Quote'}
                          </span>
                        </DropdownMenuItem>

                        {role != userRoleEnum.AGENT && (
                          <DropdownMenuItem
                            onClick={() => {
                              setIssuing(true);
                              setMessage({
                                content:
                                  'Generating Policy Bundle. NOTE: At this time, the Policy is not yet considered Bound',
                                level: 'INFO',
                              });
                              issuePolicy.mutate({
                                path: { account_id: policy!.pk! },
                              });
                            }}
                            disabled={issuing || !editMode}
                          >
                            <span className="ml-2">
                              {issuing ? 'Issuing Policy...' : 'Issue Policy'}
                            </span>
                          </DropdownMenuItem>
                        )}

                        <DropdownMenuItem
                          onClick={() => {
                            setLoading(true);
                            rate.mutate({ path: { account_id: policy!.pk! } });
                          }}
                          disabled={!editMode}
                        >
                          <span className="ml-2">Rate</span>
                        </DropdownMenuItem>
                        {role != userRoleEnum.AGENT && (
                          <DialogTrigger
                            asChild
                            onClick={() => setDialogContent('RATING')}
                          >
                            <DropdownMenuItem disabled={!editMode}>
                              <span className="ml-2">
                                Debug Previous Rating
                              </span>
                            </DropdownMenuItem>
                          </DialogTrigger>
                        )}
                        {role == userRoleEnum.SYSTEM_ADMIN &&
                          policyBoundButTransactionInProgress && (
                            <DropdownMenuItem
                              onClick={() => {
                                if (
                                  confirm(
                                    'Are you sure you wish to lock the policy?'
                                  )
                                ) {
                                  setLoading(true);
                                  setMessage({
                                    content: 'Running policy lock',
                                    level: 'INFO',
                                  });
                                  lockTransactionAdmin.mutate({
                                    path: { account_id: policy!.pk! },
                                  });
                                }
                              }}
                              disabled={loading}
                            >
                              <span className="ml-2 text-red-600">
                                {loading
                                  ? 'Locking Policy'
                                  : '***LOCK / FINALIZE POLICY***'}
                              </span>
                            </DropdownMenuItem>
                          )}
                      </DropdownMenuContent>
                    </DropdownMenu>
                    <DialogContent className="w-full overflow-auto">
                      {dialogContent == 'RATING' && (
                        <DebugTransaction
                          transaction={policy?.latestTransaction!}
                        />
                      )}
                      {dialogContent == 'TRANSFER' && (
                        <Transfer accountId={id!} />
                      )}
                      {dialogContent == 'DECLINE' && (
                        <Decline title="Decline" accountId={id!} />
                      )}

                      {dialogContent == 'NOT_TAKEN' && (
                        <Decline title="Not Taken" accountId={id!} />
                      )}

                      {dialogContent == 'DISCARD' && (
                        <Discard accountId={id!} />
                      )}

                      {dialogContent == 'REOPEN' && <Reopen accountId={id!} />}
                    </DialogContent>
                  </Dialog>
                )}
              </div>
              <Button
                type="button"
                className="h-auto w-4/12 rounded-none bg-brand-200 px-4 text-black hover:bg-brand-300"
                onClick={handleExpanded}
              >
                <ChevronsUpDown />
              </Button>
            </div>
          </div>
        </div>
        <StandardMessageHeader message={message} />
        {submissionError && (
          <div className="fixed inset-0 flex items-center justify-center bg-black/50 p-4">
            <div className="w-full max-w-2xl rounded-lg bg-white p-6">
              <h2 className="mb-4 text-xl font-bold text-red-600">
                Policy Issuance Failed
              </h2>
              {submissionError}
              <button
                className="mt-4 rounded bg-red-600 px-4 py-2 text-white hover:bg-red-700"
                onClick={() => setSubmissionError(null)}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </section>
    </header>
  );
};

export default Header;
